import React, { useEffect, useState } from "react";
import style from './index.module.scss';
import SessionService from "../../service/session/session.service";
import { useNavigate } from "react-router-dom";
import Panel from "../../theme/component/panel/panel.component";
import {BillingService} from "../../service/billing/billing.service";
import {PaymentPicker} from "../../theme/component/payment-picker";
import { useTranslation } from 'react-i18next';

import {
    BrandAccessBenefitsSection, BrandMonthlyPlan, BrandYearlyPlan
} from "../../theme/component/payment-plans";

type PayPalPlan = {
    planId: string,
    name: string
}

const isProduction = window.location.hostname === 'influspace.com'

const brandAccessMonthly = {
    planId: isProduction ? 'P-0NV86914HN9171738MY4OZXA' : 'P-63340961HC971183YMWYC55A',
    name: 'Monthly Payment Plan for Brand Access'
} as PayPalPlan

const brandAccessYearly = {
    planId: isProduction ?  'P-9NG5521757479102WMY4O2BQ' : 'P-1AY65338S2323582HMWYC5AQ',
    name: 'Yearly Payment Plan for Brand Access'
} as PayPalPlan


const plans = [brandAccessYearly, brandAccessMonthly]

export default function BrandAccess() {

    const [planId, setPlanId] = useState<string>(undefined)
    const [planName, setPlanName] = useState<string>(undefined)

    const navigate = useNavigate()
    const { t, i18n } = useTranslation();

    useEffect(() => {
        setPlanName(plans.find(plan => plan.planId === planId)?.name)
    }, [planId]);


    useEffect(() => {
        const session = SessionService.getSessionDetails()
        if (session.premium) {
            navigate('/explore')
        }
    }, [navigate]);

    async function createSubscription(data: any, actions: any)  {
        return actions.subscription.create({
            plan_id: planId,
        });
    }

    async function onApprove(data: any, actions: any) {
        BillingService.subscribe(data.subscriptionID, 'paypal').then(() => window.location.reload())
    }


    if (planId) {
        return <PaymentPicker name={planName} createSubscription={createSubscription} onApprove={onApprove} />
    }

    return (
        <section>
            <Panel className={style.panel}>
                <h1 className={style.header}>{t('brand_access_title')}</h1>
                <h3 className={style.topText}>{t('brand_access_description')}</h3>
                <BrandAccessBenefitsSection></BrandAccessBenefitsSection>
                <div className={style.plansGrid}>
                    <BrandMonthlyPlan planId={brandAccessMonthly.planId} onBuy={planId => setPlanId(planId)} />
                    <BrandYearlyPlan planId={brandAccessYearly.planId} onBuy={planId => setPlanId(planId)} />
                </div>
            </Panel>
        </section>
    )


}