import React, {forwardRef, useEffect, useState} from 'react'
import InputButton from '../../../../theme/component/input-button'
import InputText from '../../../../theme/component/input-text/input-text.component'
import {AccountService} from '../../../../service/account/account.service'
import {ThirdPartyAuthorizationService} from "../../../../service/third-party-authorization/third-party-authorization.service"
import {Criteria} from "../../../../theme/component/criteria"
import style from '../../join.module.scss'
import useDebounce from "../../../../theme/hooks/use-debounce.component";
import {useTranslation} from "react-i18next";

type PanelJoinProps = {
    onCompletion: () => void;
    onMail: (mail: string) => void;
    onPassword: (username: string) => void;
    referredBy: string;
}

function PanelJoin({ onCompletion, onMail, onPassword, referredBy }: PanelJoinProps, ref: any) {
    const {t} = useTranslation()

    const [mail, setMail] = useState<string>('')
    const [mailError, setMailError] = useState<string>(undefined)

    const mailDebounced = useDebounce(mail, 500)
    const [mailValid, setMailValid] = useState(false)

    const [password, setPassword] = useState<string>('')
    const [passwordError, setPasswordError] = useState<string>(undefined)

    const [passwordContainsNumber, setPasswordContainsNumber] = useState<boolean>(false)
    const [passwordContainsSpecialCharacter, setPasswordContainsSpecialCharacter] = useState<boolean>(false)
    const [passwordContainsTenCharacters, setPasswordContainsTenCharacters] = useState<boolean>(false)
    const [passwordValid, setPasswordValid] = useState(false)

    const [disableSignup, setDisableSignup] = useState<boolean>(false)

    useEffect(() => {

        async function validateMail() {
            if (mail === '') {
                return
            }

            try {
                await AccountService.mailAvailable(mailDebounced)

                setMailValid(true)
                setMailError('')
            } catch (error) {
                setMailValid(false)
                setMailError(error?.description)
            }
        }

        validateMail()
    }, [mailDebounced]);

    async function thirdPartySignIn(provider: string, referredBy: string) {
        const url = await ThirdPartyAuthorizationService.getAuthorizeEndpoint(provider, referredBy)

        console.log(url)
        window.location.href = url
    }

    function validatePassword(password: string) {
        setPassword(password)

        const passwordContainsNumber = /[0-9]/.test(password)
        const passwordContainsSpecialCharacter = /[^a-zA-Z0-9]/.test(password)
        const passwordContainsTenCharacters = password.length >= 10

        const passwordValid = passwordContainsNumber && passwordContainsSpecialCharacter && passwordContainsTenCharacters

        setPasswordContainsNumber(passwordContainsNumber)
        setPasswordContainsSpecialCharacter(passwordContainsSpecialCharacter)
        setPasswordContainsTenCharacters(passwordContainsTenCharacters)

        setPasswordValid(passwordValid)
    }

    function validateForm() {
        const formValid = mailValid && passwordValid

        if (formValid === false) {
            console.log(`form invalid mail validation: ${mailValid} password validation: ${passwordValid}`)
            return
        }

        onMail(mail)
        onPassword(password)

        onCompletion()
    }

    const passwordLabel = password.length > 0 ? t('join.password') : undefined

    const passwordCriteria = password.length > 0 ? (
        <div className={style.criteria_container}>
            <Criteria fulfilled={passwordContainsNumber}>{t('join.criteria_contains_numbers')}</Criteria>
            <Criteria fulfilled={passwordContainsSpecialCharacter}>{t('join.criteria_contains_special_characters')}</Criteria>
            <Criteria fulfilled={passwordContainsTenCharacters}>{t('join.criteria_ten_characters')}</Criteria>
        </div>
    ) : undefined

    return (
        <div ref={ref}>
            <div className='mb2 mt2'>
                <InputText placeholder={t('join.email_address')} initialValue={mail} onChange={setMail} error={mailError} label={t('')}/>
            </div>
            <div className='mb3'>
                <InputText placeholder={t('join.password')} isPassword={true} error={passwordError} initialValue={password} onChange={validatePassword} onEnter={validateForm} label={passwordLabel} />
                {passwordCriteria}
            </div>
            <div className='mb2'>
                <InputButton label={t('join.sign_up')} fill={true} onClick={validateForm} disabled={disableSignup} />
            </div>
            <div className='mb3'>
                <div className={`${style.or} mb1`}>
                    <span className={style.label}>{t('join.or')}</span>
                </div>
                <div className={`${style.google_authentication_button} mb2`} onClick={() => thirdPartySignIn('google', referredBy)}>
                    <span className={style.icon}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48">
                            <path fill="#EA4335"
                                  d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"></path>
                            <path fill="#4285F4"
                                  d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"></path>
                            <path fill="#FBBC05"
                                  d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"></path>
                            <path fill="#34A853"
                                  d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"></path>
                            <path fill="none" d="M0 0h48v48H0z"></path>
                        </svg>
                    </span>
                    <span className={style.label}>{t('join.signup_google')}</span>
                </div>
                {/*<div className={style.apple_authentication_button}>*/}
                {/*    <span className={style.icon}></span>*/}
                {/*    <span className={style.label}>Sign up with Apple</span>*/}
                {/*</div>*/}
            </div>
            <div className={`${style.line} mb3`}/>
        </div>
    )
}

export default forwardRef(PanelJoin)
