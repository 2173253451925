import {ITrackingPayload} from "./model/ITrackingPayload";
import {AnalyticsService} from "./analytics.service";

export class UTMTrackingService {
    static async trackPageView(url: string, query: URLSearchParams) {
        const utm_source = query.get('utm_source');
        const utm_medium = query.get('utm_medium');
        const utm_campaign = query.get('utm_campaign');

        if (!utm_source || !utm_medium || !utm_campaign) {
            return; // Keine UTM-Parameter, nichts zu tun
        }

        const trackingPayload: ITrackingPayload = {
            utm_source,
            utm_medium,
            utm_campaign,
            event_source_url: url,
        };

        try {
            await AnalyticsService.track(trackingPayload);
            console.log('Page view tracked successfully:', trackingPayload);
        } catch (error) {
            console.error('Failed to track PageView:', error);
        }
    }
}
