import React from "react";
import {Link} from "react-router-dom";
import style from "../menu.module.scss";
import {conditionalClass} from "../../../../../../utility/conditionalClass.utility";
import {classNames} from "../../../../../../utility/className.utility";

type OptionProps = {
    selected: boolean;
    hasNewMessages?: boolean; // Indikator für neue Nachrichten
    icon: React.ElementType; // Dynamisches Icon als Prop
    linkTo: string; // Dynamischer Link als Prop
    name?: string;
};

export function MobileOption({
                    selected,
                    hasNewMessages,
                    icon: Icon, // Standard-Icon ist das InboxIcon, kann aber überschrieben werden
                    linkTo, // Standard-Link ist "/inbox", kann überschrieben werden
                    name
                }: OptionProps) {

    const selectedStyle = conditionalClass(selected, style.current);

    return (
        <Link to={linkTo} className={classNames(style.item, selectedStyle)}>
            <Icon fill={selected ? "rgb(0, 83, 255)" : "rgb(54, 54, 54)"} />
            <span className={conditionalClass(selected, style.lineIndicator)}>{name}</span>
        </Link>
    );
};