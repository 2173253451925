// OfferPreview.tsx
import React from 'react';
import { IOffer } from "../../../../../../service/marketplace/model/offer.model";
import style from './index.module.scss';
import {Config} from "../../../../../../service/config/config";
import defaultAvatar from "../../../../../../theme/resource/image/default/avatar_default.png";
import {
    IKeyword
} from "../../../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import PlacementIcon from "../../../../../../theme/resource/reactIcons/placementIcon";
import DealIcon from "../../../../../../theme/resource/reactIcons/dealIcon";
import InfluencerIcon from "../../../../../../theme/resource/reactIcons/influencerIcon";
import InfluspaceIcon from "../../../../../../theme/resource/reactIcons/influspaceIcon";
import VerifiedIcon from "../../../../../../theme/resource/reactIcons/verifiedIcon";
import {ConnectIcon} from "../../../../../../components/icon/ConnectIcon";

type OfferPreviewProps = {
    offer: IOffer;
};

const OfferPreview: React.FC<OfferPreviewProps> = ({ offer }) => {
    if (!offer) return null;




    const avatar = offer.image ? `/api/image?ref=${offer.image}` : offer.avatarUrl;
    const avatarUrl = avatar ? Config.host + avatar : defaultAvatar;
    console.log(avatarUrl)

    function KeywordBar({ keywords }: { keywords: IKeyword[] }) {
        return (
            <div className={style.keywords}>
                {keywords.slice(0, 6).map((keyword) => (
                    <div key={keyword.ref}>
                        <span className={style.tag_view}>
                            {keyword.word}
                        </span>
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div id="offerPreview" className={style.offerContainer}>
            <div className={style.background}>
                <div className={style.backgroundBox}></div>
                <img src={avatarUrl} alt="Background"
                     className={style.backgroundImage}/>
            </div>
            <div className={style.content}>
            <div className={style.header}>
                    <span className={style.headerText}>Influencer Marketing</span>
                    <div className={style.headerIcon}>
                        <InfluspaceIcon size={56} backgroundColor={'white'}/>
                        <div className={style.headerSmallIcon}>
                            <VerifiedIcon color={'premium'} size={28} backgroundColor={'white'}/>
                        </div>
                    </div>
                    <span className={style.headerText}>Brand Sponsorship</span>
                </div>
                <div className={style.mainContent}>
                <h1 className={style.title}>{offer.name}</h1>
                    <div className={style.imageAndDescription}>
                        <div className={style.imageBox}>
                            <img src={avatarUrl} alt="Product" className={style.mainImage}/>
                        </div>
                        <div className={style.descriptionBox}>
                            <div className={style.description}>{offer.description}</div>
                        </div>
                    </div>
                    <div className={style.descriptionBox}>
                        <div className={style.info_item_labels}>
                            {offer?.collaborations?.productPlacement && (
                                <div className={style.tooltip}>
                                    <PlacementIcon size={24}/>
                                    <div className={style.info_item_name}>Placement</div>
                                </div>
                            )}
                            {offer?.collaborations?.freeProductPlacement && (
                                <div className={style.tooltip}>
                                    <DealIcon size={24}/>
                                    <div className={style.info_item_name}>Free Product</div>
                                </div>
                            )}
                            {offer?.collaborations?.influencerCollaboration && (
                                <div className={style.tooltip}>
                                    <InfluencerIcon size={24}/>
                                    <div className={style.info_item_name}>Collaborations</div>
                                </div>
                            )}
                        </div>
                        <div className={style.keywords}>
                            <KeywordBar keywords={offer.keywords}/>
                        </div>
                        <div className={style.authorContainer}>
                            <span className={style.iconWrapper}>
                                <InfluspaceIcon size={48} color={'black'}/>
                            </span>
                            <span>@{offer?.author.username}</span>
                        </div>
                    </div>
                </div>
                <div className={style.footerBox}>
                    <ConnectIcon size={400}/>
                </div>
            </div>
        </div>
    );
};

export default OfferPreview;
