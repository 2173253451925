import React, { useState, useEffect, useContext, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useClickOutside from '../../../../../theme/hooks/use-click-outside.component';
import { useLocation } from 'react-router-dom';
import style from './logo.module.scss';
import SessionService from '../../../../../service/session/session.service';
import { useTranslation } from "react-i18next";
import HasMessagesContext from "../../../../../state/context/has-messages";

export default function Logo() {
    const [quickactionsOpen, setQuickactionsOpen] = useState<boolean>(false);
    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { hasNewMessages } = hasMessagesContext;

    const menuRef = useClickOutside(closeMenu);
    const quickationRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [currentPage, setCurrentPage] = useState<string>('');

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setCurrentPage('home');
        } else if (path.includes('/explore')) {
            setCurrentPage('explore');
        } else if (path.includes('/inbox')) {
            setCurrentPage('inbox');
        } else if (path.includes('/marketplace')) {
            setCurrentPage('marketplace');
        }
    }, [location]);

    function openMenu() {
        setQuickactionsOpen(true);
        const qActions = quickationRef.current as HTMLElement;

        if (qActions?.style) qActions.style.zIndex = '200';
    }

    function closeMenu() {
        setQuickactionsOpen(false);
        setTimeout(() => {
            const qActions = quickationRef.current as HTMLElement;

            if (qActions?.style) qActions.style.zIndex = '0';
        }, 400);
    }

    function navigateToHome() {
        if (SessionService.isAuthenticated()) {
            navigate('/explore');
            return;
        }

        navigate('/');
    }


    return (
        <span ref={menuRef}>
            <span className={style.logo}>
                <span className={`${style.item} ${currentPage === 'home' ? style.current : ''}`} onClick={navigateToHome}>
                    <span className={style.home} />
                </span>
            </span>
        </span>
    );
}
