import React, {useEffect} from 'react';
import {useState} from 'react'
import {Blurhash} from 'react-blurhash'
import {isBlurhashValid} from 'blurhash';
import {Config} from '../../../service/config/config';

type ImageContentProps = {
    name?: string
    preview: string
    url: string
    height?: string
    width?: string
    defaultImage?: any
    className?: string
}

export default function ImageContent({name, preview, url, height, width, defaultImage, className}: ImageContentProps) {
    const [isLoaded, setLoaded] = useState(false)

    useEffect(() => {
        const image = new Image()

        image.onload = () => {
            setLoaded(true)
        }

        image.onerror = () => {
            image.src = defaultImage
        }

        image.src = Config.host + url
    }, [])

    const isValidPreview = isBlurhashValid(preview).result

    if (isValidPreview === false) {
        if (isValidPreview === false) {
            return <img
                        className={className}
                        src={defaultImage} />
        }

    }

    return (
        <>
            <div style={{ display: isLoaded ? 'none' : 'inline' }}>
                <Blurhash
                    hash={preview}
                    height={height}
                    width={width}
                    resolutionX={32}
                    resolutionY={32}
                    punch={1}
                    className={className}
                />
            </div>
            <img
                className={className}
                src={Config.host + url}
                alt={name}
                style={{ display: isLoaded ? 'inline' : 'none' }} />
        </>
    )
}