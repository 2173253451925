import React from 'react';

type IconProps = {
    size?: number;
}

export function ConnectIcon({ size = 16 }: IconProps) {
    return (
        <svg
            width={size}
            height={size / 2}
            viewBox="0 0 46 16"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
            <rect id="connect_on_influspace" x="0" y="0" width="45.435" height="16" style={{ fill: 'none' }} />
            <path
                d="M44.953,4.629c-0,-2.184 -1.773,-3.957 -3.957,-3.957l-36.556,0c-2.184,0 -3.957,1.773 -3.957,3.957l-0,6.742c-0,2.184 1.773,3.957 3.957,3.957l36.556,-0c2.184,-0 3.957,-1.773 3.957,-3.957l-0,-6.742Z"
                style={{ fill: 'rgb(13,13,13)' }}
            />
            <rect x="15.55" y="8.006" width="0.877" height="4.067" style={{ fill: 'white', fillRule: 'nonzero' }} />
            <path
                d="M17.955,12.074l-0.843,-0l-0,-3.023l0.793,0l0.05,0.645l0,2.378Zm-0.346,-1.601c0,-0.305 0.049,-0.569 0.148,-0.789c0.099,-0.221 0.238,-0.391 0.416,-0.51c0.179,-0.119 0.387,-0.179 0.623,-0.179c0.19,0 0.357,0.037 0.499,0.112c0.142,0.074 0.254,0.196 0.335,0.364c0.081,0.169 0.122,0.398 0.122,0.686l-0,1.917l-0.847,-0l0,-1.922c0,-0.125 -0.018,-0.221 -0.054,-0.289c-0.037,-0.068 -0.089,-0.116 -0.158,-0.143c-0.069,-0.027 -0.154,-0.04 -0.254,-0.04c-0.103,-0 -0.192,0.019 -0.267,0.057c-0.075,0.038 -0.138,0.092 -0.187,0.163c-0.05,0.071 -0.086,0.154 -0.111,0.25c-0.024,0.096 -0.036,0.2 -0.036,0.312l-0.229,0.011Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M20.559,12.074l0,-3.28c0,-0.346 0.101,-0.611 0.303,-0.795c0.202,-0.183 0.475,-0.275 0.82,-0.275c0.076,0 0.149,0.005 0.219,0.015c0.07,0.011 0.138,0.024 0.203,0.041l-0.006,0.651c-0.035,-0.008 -0.073,-0.013 -0.113,-0.017c-0.04,-0.004 -0.084,-0.005 -0.133,-0.005c-0.141,-0 -0.251,0.033 -0.329,0.099c-0.078,0.066 -0.118,0.161 -0.118,0.286l0,3.28l-0.846,-0Zm1.441,-2.408l-1.885,-0l-0,-0.615l1.885,0l0,0.615Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <rect x="22.445" y="7.783" width="0.846" height="4.291" style={{ fill: 'white', fillRule: 'nonzero' }} />
            <path
                d="M25.71,9.051l0.844,0l-0,3.023l-0.791,-0l-0.053,-0.729l0,-2.294Zm0.338,1.662c0,0.272 -0.041,0.515 -0.124,0.728c-0.083,0.213 -0.208,0.381 -0.374,0.504c-0.167,0.123 -0.377,0.184 -0.63,0.184c-0.298,0 -0.54,-0.087 -0.725,-0.261c-0.186,-0.174 -0.278,-0.461 -0.278,-0.862l-0,-1.955l0.843,0l0,1.961c0,0.103 0.018,0.186 0.052,0.25c0.035,0.064 0.083,0.111 0.145,0.14c0.063,0.029 0.134,0.043 0.214,0.043c0.162,0 0.289,-0.032 0.381,-0.095c0.093,-0.063 0.158,-0.15 0.196,-0.26c0.038,-0.11 0.057,-0.233 0.057,-0.371l0.243,-0.006Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M28.766,11.23c0,-0.056 -0.016,-0.104 -0.049,-0.145c-0.032,-0.041 -0.092,-0.079 -0.18,-0.115c-0.087,-0.035 -0.214,-0.071 -0.38,-0.106c-0.203,-0.045 -0.386,-0.105 -0.549,-0.182c-0.163,-0.076 -0.291,-0.175 -0.384,-0.297c-0.093,-0.122 -0.139,-0.27 -0.139,-0.443c-0,-0.169 0.048,-0.326 0.145,-0.469c0.097,-0.144 0.236,-0.259 0.418,-0.347c0.181,-0.087 0.399,-0.131 0.652,-0.131c0.264,0 0.492,0.042 0.684,0.126c0.192,0.084 0.339,0.199 0.442,0.346c0.102,0.147 0.153,0.316 0.153,0.506l-0.843,-0c-0,-0.114 -0.035,-0.206 -0.105,-0.278c-0.07,-0.072 -0.181,-0.108 -0.334,-0.108c-0.082,0 -0.154,0.014 -0.215,0.042c-0.062,0.028 -0.109,0.065 -0.141,0.111c-0.033,0.045 -0.049,0.099 -0.049,0.16c-0,0.056 0.018,0.105 0.053,0.148c0.035,0.043 0.093,0.08 0.173,0.112c0.08,0.032 0.187,0.06 0.322,0.084c0.214,0.041 0.407,0.097 0.581,0.169c0.173,0.072 0.311,0.169 0.413,0.293c0.102,0.124 0.154,0.288 0.154,0.493c-0,0.179 -0.053,0.339 -0.158,0.479c-0.105,0.141 -0.254,0.251 -0.447,0.331c-0.193,0.08 -0.42,0.12 -0.68,0.12c-0.285,0 -0.526,-0.051 -0.721,-0.153c-0.196,-0.103 -0.344,-0.232 -0.444,-0.39c-0.101,-0.157 -0.151,-0.318 -0.151,-0.482l0.799,0c0.005,0.101 0.033,0.183 0.082,0.246c0.05,0.063 0.114,0.11 0.193,0.14c0.079,0.03 0.166,0.044 0.261,0.044c0.097,0 0.178,-0.012 0.245,-0.037c0.066,-0.025 0.116,-0.061 0.149,-0.106c0.034,-0.046 0.05,-0.1 0.05,-0.161Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M30.934,13.236l-0.844,-0l0,-4.185l0.783,0l0.061,0.581l0,3.604Zm1.9,-2.649c-0,0.293 -0.045,0.555 -0.136,0.788c-0.09,0.233 -0.222,0.417 -0.396,0.552c-0.175,0.135 -0.389,0.202 -0.644,0.202c-0.248,0 -0.452,-0.067 -0.611,-0.202c-0.159,-0.135 -0.282,-0.317 -0.37,-0.546c-0.087,-0.229 -0.151,-0.483 -0.19,-0.76l0,-0.087c0.037,-0.296 0.1,-0.56 0.189,-0.792c0.088,-0.231 0.212,-0.414 0.371,-0.547c0.159,-0.133 0.362,-0.2 0.608,-0.2c0.255,0 0.47,0.064 0.645,0.192c0.175,0.127 0.308,0.306 0.398,0.536c0.091,0.23 0.136,0.499 0.136,0.806l-0,0.058Zm-0.844,-0.058c0,-0.164 -0.018,-0.31 -0.054,-0.437c-0.037,-0.128 -0.096,-0.229 -0.178,-0.302c-0.082,-0.074 -0.191,-0.11 -0.327,-0.11c-0.104,-0 -0.193,0.016 -0.266,0.048c-0.074,0.033 -0.135,0.081 -0.182,0.144c-0.047,0.064 -0.083,0.141 -0.106,0.234c-0.023,0.092 -0.037,0.198 -0.041,0.319l0,0.308c0.008,0.138 0.032,0.26 0.072,0.368c0.04,0.108 0.102,0.194 0.188,0.256c0.086,0.062 0.199,0.094 0.341,0.094c0.138,-0 0.247,-0.039 0.327,-0.118c0.08,-0.078 0.138,-0.183 0.173,-0.314c0.035,-0.131 0.053,-0.275 0.053,-0.432l0,-0.058Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M34.929,10.059c-0,-0.135 -0.034,-0.243 -0.101,-0.324c-0.067,-0.08 -0.174,-0.12 -0.321,-0.12c-0.084,0 -0.156,0.014 -0.218,0.042c-0.061,0.028 -0.108,0.068 -0.14,0.119c-0.031,0.051 -0.047,0.114 -0.047,0.189l-0.844,-0c0,-0.179 0.053,-0.342 0.16,-0.488c0.106,-0.146 0.256,-0.263 0.449,-0.351c0.194,-0.087 0.422,-0.131 0.685,-0.131c0.234,0 0.444,0.04 0.628,0.119c0.185,0.079 0.33,0.198 0.436,0.356c0.106,0.158 0.159,0.357 0.159,0.595l0,1.263c0,0.179 0.011,0.32 0.032,0.424c0.022,0.105 0.052,0.196 0.091,0.274l0,0.048l-0.849,-0c-0.041,-0.086 -0.071,-0.193 -0.091,-0.322c-0.019,-0.128 -0.029,-0.259 -0.029,-0.391l-0,-1.302Zm0.114,0.651l-0.43,0c-0.205,0 -0.353,0.045 -0.444,0.133c-0.091,0.089 -0.137,0.195 -0.137,0.32c0,0.095 0.034,0.173 0.101,0.235c0.067,0.061 0.157,0.092 0.271,0.092c0.117,-0 0.218,-0.023 0.303,-0.069c0.084,-0.045 0.15,-0.1 0.195,-0.164c0.046,-0.065 0.068,-0.124 0.066,-0.178l0.218,0.363c-0.03,0.075 -0.07,0.152 -0.119,0.232c-0.049,0.08 -0.112,0.155 -0.188,0.224c-0.077,0.069 -0.169,0.124 -0.278,0.167c-0.109,0.043 -0.237,0.064 -0.385,0.064c-0.189,0 -0.363,-0.038 -0.519,-0.115c-0.157,-0.078 -0.28,-0.185 -0.372,-0.322c-0.091,-0.137 -0.137,-0.293 -0.137,-0.47c0,-0.317 0.118,-0.562 0.352,-0.736c0.235,-0.175 0.584,-0.262 1.048,-0.262l0.45,0l0.005,0.486Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M37.639,11.451c0.094,-0 0.178,-0.018 0.251,-0.053c0.073,-0.036 0.129,-0.086 0.169,-0.151c0.04,-0.065 0.061,-0.143 0.063,-0.232l0.793,-0c-0.002,0.218 -0.059,0.411 -0.17,0.578c-0.112,0.168 -0.263,0.299 -0.453,0.394c-0.19,0.095 -0.403,0.142 -0.64,0.142c-0.312,0 -0.573,-0.067 -0.78,-0.203c-0.208,-0.136 -0.363,-0.32 -0.467,-0.551c-0.103,-0.231 -0.155,-0.489 -0.155,-0.774l0,-0.075c0,-0.287 0.052,-0.546 0.155,-0.777c0.104,-0.231 0.259,-0.414 0.467,-0.55c0.207,-0.136 0.467,-0.204 0.778,-0.204c0.249,0 0.469,0.048 0.658,0.144c0.189,0.096 0.337,0.233 0.444,0.411c0.107,0.178 0.161,0.39 0.163,0.638l-0.793,-0c-0.002,-0.095 -0.021,-0.181 -0.057,-0.258c-0.037,-0.078 -0.091,-0.14 -0.164,-0.186c-0.073,-0.047 -0.162,-0.07 -0.268,-0.07c-0.149,-0 -0.262,0.04 -0.339,0.12c-0.078,0.08 -0.13,0.185 -0.158,0.314c-0.028,0.13 -0.042,0.269 -0.042,0.418l-0,0.075c-0,0.151 0.013,0.291 0.04,0.421c0.027,0.129 0.08,0.233 0.16,0.311c0.079,0.079 0.194,0.118 0.345,0.118Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            <path
                d="M40.773,12.129c-0.319,0 -0.591,-0.066 -0.817,-0.198c-0.227,-0.132 -0.4,-0.309 -0.52,-0.531c-0.12,-0.221 -0.18,-0.466 -0.18,-0.734l-0,-0.109c-0,-0.302 0.057,-0.571 0.172,-0.806c0.114,-0.236 0.278,-0.421 0.49,-0.555c0.212,-0.134 0.466,-0.201 0.76,-0.201c0.292,0 0.538,0.062 0.737,0.185c0.2,0.122 0.35,0.295 0.453,0.518c0.102,0.222 0.154,0.483 0.154,0.783l-0,0.358l-2.411,-0l-0,-0.567l1.581,-0l-0,-0.067c-0,-0.105 -0.019,-0.197 -0.056,-0.277c-0.037,-0.08 -0.094,-0.142 -0.169,-0.187c-0.075,-0.045 -0.174,-0.067 -0.295,-0.067c-0.138,-0 -0.248,0.039 -0.332,0.117c-0.084,0.079 -0.145,0.184 -0.183,0.316c-0.038,0.132 -0.057,0.282 -0.057,0.45l-0,0.109c-0,0.154 0.028,0.29 0.086,0.408c0.058,0.117 0.141,0.209 0.249,0.275c0.108,0.066 0.238,0.099 0.391,0.099c0.143,-0 0.277,-0.027 0.402,-0.08c0.125,-0.053 0.231,-0.136 0.319,-0.25l0.407,0.467c-0.095,0.136 -0.241,0.26 -0.44,0.374c-0.198,0.114 -0.445,0.17 -0.741,0.17Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />
            {/* Kreise und Verläufe */}
            <g>
                <circle cx="8.367" cy="8" r="4.264" style={{ fill: 'white' }} />
                <path
                    d="M6.721,12.675c-1.938,-0.678 -3.328,-2.517 -3.328,-4.678c-0,-2.736 2.229,-4.957 4.974,-4.957c2.745,0 4.973,2.221 4.973,4.957c0,2.16 -1.39,4 -3.327,4.678l-0,-3.998c-0,-0.453 -0.369,-0.821 -0.823,-0.821l-1.646,0c-0.455,0 -0.823,0.368 -0.823,0.821l-0,3.998Zm1.711,-7.978c0.624,0 1.13,0.505 1.13,1.126c-0,0.621 -0.506,1.126 -1.13,1.126c-0.624,0 -1.13,-0.505 -1.13,-1.126c0,-0.621 0.506,-1.126 1.13,-1.126Z"
                    style={{ fill: 'url(#_Linear1)' }}
                />
                <path
                    d="M6.721,12.675c-1.938,-0.678 -3.328,-2.517 -3.328,-4.678c-0,-2.736 2.229,-4.957 4.974,-4.957c2.745,0 4.973,2.221 4.973,4.957c0,2.16 -1.39,4 -3.327,4.678l-0,-3.998c-0,-0.453 -0.369,-0.821 -0.823,-0.821l-1.646,0c-0.455,0 -0.823,0.368 -0.823,0.821l-0,3.998Zm1.711,-7.978c0.624,0 1.13,0.505 1.13,1.126c-0,0.621 -0.506,1.126 -1.13,1.126c-0.624,0 -1.13,-0.505 -1.13,-1.126c0,-0.621 0.506,-1.126 1.13,-1.126Z"
                    style={{ fill: 'url(#_Linear2)' }}
                />
                <path
                    d="M8.353,3.04c2.737,0 4.96,2.223 4.96,4.96c-0,2.737 -2.223,4.96 -4.96,4.96c-2.738,-0 -4.96,-2.223 -4.96,-4.96c-0,-2.737 2.222,-4.96 4.96,-4.96Zm-0,0.696c2.353,0 4.264,1.911 4.264,4.264c-0,2.353 -1.911,4.264 -4.264,4.264c-2.354,-0 -4.264,-1.911 -4.264,-4.264c-0,-2.353 1.91,-4.264 4.264,-4.264Z"
                    style={{ fill: 'url(#_Linear3)' }}
                />
                <path
                    d="M8.353,3.04c2.737,0 4.96,2.223 4.96,4.96c-0,2.737 -2.223,4.96 -4.96,4.96c-2.738,-0 -4.96,-2.223 -4.96,-4.96c-0,-2.737 2.222,-4.96 4.96,-4.96Zm-0,0.696c2.353,0 4.264,1.911 4.264,4.264c-0,2.353 -1.911,4.264 -4.264,4.264c-2.354,-0 -4.264,-1.911 -4.264,-4.264c-0,-2.353 1.91,-4.264 4.264,-4.264Z"
                    style={{ fill: 'url(#_Linear4)' }}
                />
            </g>

            <path
                d="M17.563,5.322C17.553,5.488 17.507,5.635 17.427,5.764C17.346,5.892 17.234,5.993 17.09,6.066C16.947,6.138 16.774,6.175 16.573,6.175C16.362,6.175 16.181,6.127 16.029,6.031C15.877,5.936 15.76,5.799 15.678,5.623C15.597,5.446 15.556,5.237 15.556,4.995V4.846C15.556,4.603 15.598,4.393 15.683,4.216C15.767,4.04 15.885,3.903 16.038,3.807C16.19,3.711 16.369,3.663 16.576,3.663C16.782,3.663 16.956,3.7 17.097,3.776C17.239,3.852 17.348,3.955 17.426,4.086C17.504,4.216 17.55,4.366 17.566,4.534H17.039C17.029,4.39 16.99,4.282 16.923,4.207C16.855,4.133 16.739,4.096 16.576,4.096C16.468,4.096 16.378,4.122 16.306,4.175C16.234,4.227 16.181,4.308 16.145,4.419C16.109,4.529 16.091,4.67 16.091,4.842V4.995C16.091,5.164 16.107,5.304 16.139,5.415C16.171,5.525 16.222,5.608 16.293,5.662C16.363,5.716 16.456,5.743 16.573,5.743C16.675,5.743 16.758,5.728 16.824,5.697C16.89,5.666 16.941,5.62 16.975,5.557C17.01,5.494 17.031,5.416 17.038,5.322H17.563Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M17.776,5.217C17.776,5.04 17.809,4.882 17.877,4.742C17.944,4.603 18.042,4.493 18.17,4.413C18.299,4.332 18.455,4.292 18.638,4.292C18.824,4.292 18.981,4.332 19.111,4.413C19.24,4.493 19.338,4.603 19.404,4.742C19.471,4.882 19.504,5.04 19.504,5.217V5.252C19.504,5.427 19.471,5.585 19.404,5.725C19.338,5.865 19.24,5.975 19.112,6.055C18.983,6.135 18.826,6.175 18.642,6.175C18.457,6.175 18.3,6.135 18.171,6.055C18.042,5.975 17.944,5.865 17.877,5.725C17.809,5.585 17.776,5.427 17.776,5.252V5.217ZM18.284,5.252C18.284,5.347 18.296,5.434 18.319,5.512C18.342,5.59 18.379,5.652 18.431,5.698C18.483,5.744 18.553,5.767 18.642,5.767C18.729,5.767 18.799,5.744 18.851,5.698C18.903,5.652 18.94,5.59 18.963,5.512C18.986,5.434 18.997,5.347 18.997,5.252V5.217C18.997,5.124 18.986,5.038 18.963,4.959C18.94,4.88 18.903,4.817 18.851,4.77C18.799,4.723 18.728,4.7 18.638,4.7C18.551,4.7 18.481,4.723 18.429,4.77C18.377,4.817 18.34,4.88 18.318,4.959C18.295,5.038 18.284,5.124 18.284,5.217V5.252Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M20.289,6.141L19.783,6.141V4.326H20.259L20.289,4.713V6.141ZM20.081,5.18C20.081,4.996 20.111,4.838 20.17,4.706C20.23,4.573 20.313,4.471 20.42,4.399C20.528,4.328 20.652,4.292 20.794,4.292C20.909,4.292 21.008,4.314 21.094,4.359C21.18,4.404 21.247,4.477 21.295,4.578C21.344,4.679 21.368,4.817 21.368,4.99V6.141H20.86V4.987C20.86,4.912 20.849,4.854 20.827,4.813C20.805,4.772 20.774,4.744 20.732,4.727C20.691,4.711 20.64,4.703 20.58,4.703C20.518,4.703 20.465,4.715 20.419,4.737C20.374,4.76 20.337,4.793 20.307,4.836C20.277,4.878 20.255,4.928 20.241,4.986C20.226,5.043 20.219,5.106 20.219,5.173H20.081Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M22.219,6.141L21.712,6.141L21.712,4.326L22.189,4.326L22.219,4.713L22.219,6.141ZM22.011,5.18C22.011,4.996 22.041,4.838 22.1,4.706C22.159,4.573 22.243,4.471 22.35,4.399C22.457,4.328 22.582,4.292 22.724,4.292C22.838,4.292 22.938,4.314 23.024,4.359C23.109,4.404 23.176,4.477 23.225,4.578C23.274,4.679 23.298,4.817 23.298,4.99L23.298,6.141L22.79,6.141L22.79,4.987C22.79,4.912 22.779,4.854 22.757,4.813C22.735,4.772 22.703,4.744 22.662,4.727C22.621,4.711 22.57,4.703 22.509,4.703C22.448,4.703 22.394,4.715 22.349,4.737C22.304,4.76 22.266,4.793 22.237,4.836C22.207,4.878 22.185,4.928 22.17,4.986C22.156,5.043 22.149,5.106 22.149,5.173L22.011,5.18Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M24.501,6.175C24.31,6.175 24.146,6.135 24.01,6.055C23.874,5.976 23.77,5.87 23.698,5.737C23.626,5.604 23.59,5.456 23.59,5.295L23.59,5.23C23.59,5.049 23.624,4.887 23.693,4.746C23.762,4.604 23.86,4.493 23.988,4.413C24.115,4.332 24.267,4.292 24.444,4.292C24.62,4.292 24.767,4.329 24.887,4.403C25.007,4.477 25.097,4.58 25.159,4.714C25.22,4.848 25.251,5.005 25.251,5.185L25.251,5.399L23.803,5.399L23.803,5.059L24.753,5.059L24.753,5.019C24.753,4.956 24.742,4.9 24.719,4.852C24.697,4.804 24.663,4.767 24.618,4.74C24.572,4.713 24.513,4.7 24.441,4.7C24.358,4.7 24.291,4.723 24.241,4.77C24.191,4.817 24.154,4.88 24.131,4.96C24.108,5.039 24.097,5.129 24.097,5.23L24.097,5.295C24.097,5.388 24.114,5.47 24.149,5.54C24.183,5.611 24.233,5.666 24.298,5.706C24.363,5.745 24.441,5.765 24.533,5.765C24.619,5.765 24.7,5.749 24.775,5.717C24.85,5.685 24.913,5.635 24.966,5.567L25.211,5.847C25.154,5.929 25.066,6.004 24.947,6.072C24.827,6.14 24.679,6.175 24.501,6.175Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M26.275,5.767C26.332,5.767 26.382,5.756 26.426,5.735C26.469,5.714 26.503,5.684 26.527,5.644C26.551,5.605 26.564,5.559 26.565,5.505L27.041,5.505C27.04,5.636 27.006,5.752 26.939,5.852C26.872,5.953 26.781,6.032 26.667,6.089C26.553,6.146 26.425,6.175 26.283,6.175C26.095,6.175 25.939,6.134 25.814,6.052C25.689,5.97 25.596,5.86 25.534,5.722C25.472,5.583 25.441,5.428 25.441,5.257L25.441,5.211C25.441,5.039 25.472,4.884 25.534,4.745C25.596,4.606 25.689,4.496 25.814,4.414C25.939,4.333 26.095,4.292 26.281,4.292C26.431,4.292 26.563,4.321 26.677,4.378C26.79,4.436 26.879,4.518 26.943,4.625C27.008,4.732 27.04,4.86 27.041,5.008L26.565,5.008C26.564,4.951 26.552,4.9 26.531,4.853C26.509,4.807 26.476,4.77 26.432,4.742C26.389,4.714 26.335,4.7 26.271,4.7C26.182,4.7 26.114,4.724 26.067,4.772C26.021,4.82 25.989,4.883 25.973,4.961C25.956,5.038 25.947,5.122 25.947,5.211L25.947,5.257C25.947,5.347 25.956,5.432 25.972,5.509C25.988,5.587 26.02,5.649 26.067,5.696C26.115,5.743 26.184,5.767 26.275,5.767Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M28.218,4.695L27.145,4.695L27.145,4.326L28.218,4.326L28.218,4.695ZM27.911,3.878L27.911,5.574C27.911,5.623 27.917,5.661 27.93,5.688C27.943,5.715 27.963,5.733 27.991,5.743C28.019,5.753 28.054,5.758 28.095,5.758C28.125,5.758 28.152,5.757 28.176,5.755C28.199,5.753 28.219,5.75 28.236,5.747L28.234,6.129C28.193,6.144 28.148,6.155 28.099,6.163C28.051,6.171 27.996,6.175 27.936,6.175C27.827,6.175 27.733,6.156 27.653,6.12C27.573,6.084 27.512,6.026 27.468,5.946C27.425,5.866 27.404,5.761 27.404,5.631L27.404,3.878L27.911,3.878Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M29.258,5.217C29.258,5.04 29.292,4.882 29.359,4.742C29.426,4.603 29.524,4.493 29.652,4.413C29.781,4.332 29.937,4.292 30.12,4.292C30.306,4.292 30.464,4.332 30.593,4.413C30.722,4.493 30.82,4.603 30.886,4.742C30.953,4.882 30.986,5.04 30.986,5.217L30.986,5.252C30.986,5.427 30.953,5.585 30.886,5.725C30.82,5.865 30.722,5.975 30.594,6.055C30.465,6.135 30.308,6.175 30.124,6.175C29.939,6.175 29.782,6.135 29.653,6.055C29.524,5.975 29.426,5.865 29.359,5.725C29.292,5.585 29.258,5.427 29.258,5.252L29.258,5.217ZM29.766,5.252C29.766,5.347 29.778,5.434 29.801,5.512C29.824,5.59 29.861,5.652 29.913,5.698C29.965,5.744 30.035,5.767 30.124,5.767C30.211,5.767 30.281,5.744 30.333,5.698C30.385,5.652 30.422,5.59 30.445,5.512C30.468,5.434 30.48,5.347 30.48,5.252L30.48,5.217C30.48,5.124 30.468,5.038 30.445,4.959C30.422,4.88 30.385,4.817 30.333,4.77C30.281,4.723 30.21,4.7 30.12,4.7C30.033,4.7 29.964,4.723 29.912,4.77C29.86,4.817 29.822,4.88 29.8,4.959C29.778,5.038 29.766,5.124 29.766,5.217L29.766,5.252Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            <path
                d="M31.772,6.141L31.265,6.141L31.265,4.326L31.741,4.326L31.772,4.713L31.772,6.141ZM31.564,5.18C31.564,4.996 31.593,4.838 31.652,4.706C31.712,4.573 31.795,4.471 31.902,4.399C32.01,4.328 32.135,4.292 32.277,4.292C32.391,4.292 32.491,4.314 32.576,4.359C32.662,4.404 32.729,4.477 32.778,4.578C32.826,4.679 32.851,4.817 32.851,4.99L32.851,6.141L32.342,6.141L32.342,4.987C32.342,4.912 32.331,4.854 32.309,4.813C32.288,4.772 32.256,4.744 32.215,4.727C32.173,4.711 32.122,4.703 32.062,4.703C32,4.703 31.947,4.715 31.902,4.737C31.856,4.76 31.819,4.793 31.789,4.836C31.76,4.878 31.737,4.928 31.723,4.986C31.708,5.043 31.701,5.106 31.701,5.173L31.564,5.18Z"
                style={{ fill: 'white', fillRule: 'nonzero' }}
            />

            {/* Definiert die Gradienten */}
            <defs>
                <linearGradient
                    id="_Linear1"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(5.92005e-16,9.63487,-9.66817,5.89965e-16,8.36654,3.04019)"
                >
                    <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }} />
                    <stop offset="0.89" style={{ stopColor: 'rgb(0,235,81)', stopOpacity: 1 }} />
                    <stop offset="0.93" style={{ stopColor: 'rgb(0,252,12)', stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }} />
                </linearGradient>
                <linearGradient
                    id="_Linear2"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(5.92005e-16,9.63487,-9.66817,5.89965e-16,8.36654,3.04019)"
                >
                    <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }} />
                    <stop offset="0.89" style={{ stopColor: 'rgb(0,235,81)', stopOpacity: 1 }} />
                    <stop offset="0.93" style={{ stopColor: 'rgb(0,252,12)', stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }} />
                </linearGradient>
                <linearGradient
                    id="_Linear3"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(7.87483e-16,10.5464,-10.2503,5.43216e-16,8.35269,2.55671)"
                >
                    <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }} />
                    <stop offset="0.62" style={{ stopColor: 'rgb(0,202,213)', stopOpacity: 1 }} />
                    <stop offset="0.82" style={{ stopColor: 'rgb(0,227,111)', stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }} />
                </linearGradient>
                <linearGradient
                    id="_Linear4"
                    x1="0"
                    y1="0"
                    x2="1"
                    y2="0"
                    gradientUnits="userSpaceOnUse"
                    gradientTransform="matrix(7.87483e-16,10.5464,-10.2503,5.43216e-16,8.35269,2.55671)"
                >
                    <stop offset="0" style={{ stopColor: 'rgb(0,191,255)', stopOpacity: 1 }} />
                    <stop offset="0.62" style={{ stopColor: 'rgb(0,202,213)', stopOpacity: 1 }} />
                    <stop offset="0.82" style={{ stopColor: 'rgb(0,227,111)', stopOpacity: 1 }} />
                    <stop offset="1" style={{ stopColor: 'rgb(0,255,0)', stopOpacity: 1 }} />
                </linearGradient>
            </defs>
        </svg>
    );
}