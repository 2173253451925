import React, { useState } from 'react'
import InputButton from "../../../../theme/component/input-button";
import InputDropdown from "../../../../theme/component/input-dropdown/input-dropdown.component";
import OptionModel from "../../../../theme/component/input-dropdown/models/dropdown-option.model";
import {ButtonColor} from "../../../../theme/component/input-button/models/button-color.model";
import Panel from '../../../../theme/component/panel/panel.component'
import Platform from '../../../../service/network/model/Platform'
import networkService from '../../../../service/network/network.service'
import { useTranslation } from 'react-i18next';

import style from './profile-first-network-cta.module.scss'

const ProfileFirstNetworkCTA: React.FC = () => {
    const { t } = useTranslation();
    const [selectedNetwork, setSelectedNetwork] = useState<OptionModel>()
    const [disableConnect, setDisableConnect] = useState<boolean>(false)

    const options: OptionModel[] = [
        { label: 'Facebook', value: 'facebook', icon: 'facebook' },
        { label: 'Instagram', value: 'instagram', icon: 'instagram' },
        { label: 'Tiktok', value: 'tiktok', icon: 'tiktok' },
        { label: 'Twitch', value: 'twitch', icon: 'twitch' },
        { label: 'X (Twitter)', value: 'twitter', icon: 'twitter' },
        { label: 'YouTube', value: 'youtube', icon: 'youtube' }
    ]

    async function handleConnect() {
        if (selectedNetwork === undefined) {
            setDisableConnect(true)
            return
        }

        try {
            window.location.href = await networkService.connect(selectedNetwork.value as Platform)
        } catch (error) {
            console.log(error)
        }
    }

    function handleSelect(selectedNetwork: OptionModel) {
        if (disableConnect)
            setDisableConnect(false)

        setSelectedNetwork(selectedNetwork)
    }

    return (
        <Panel overrideStyle={{width:'100%', padding:'1rem', borderRadius:'24px !important'}}>
            <div className={style.container}>
                <div className={style.icon}/>
                <div className={style.content}>
                    <div className={style.title}>
                        <strong>{t('profileFirstNetworkCTA_text')}</strong> - {t('profileFirstNetworkCTA_text2')}
                    </div>
                </div>
                <div className={style.buttons}>
                    <div className={style.dropdown}>
                        <InputDropdown
                            placeholder={t('profileFirstNetworkCTA_selectNetwork')}
                            options={options}
                            onChange={handleSelect}
                        />
                    </div>
                    <InputButton
                        label={t('profileFirstNetworkCTA_button')}
                        color={ButtonColor.Success}
                        className={`${style.connect_button} ${style[selectedNetwork?.value]}`}
                        onClick={handleConnect}
                        disabled={disableConnect}
                    />
                </div>
            </div>
        </Panel>
    )
}

export default ProfileFirstNetworkCTA