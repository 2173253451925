import React, { useState } from 'react'
import MenuItem from './component/menu-item/menu-item.component'
import style from './settings-menu.module.scss'
import { DetailView } from "../../model/SettingViewsModel";
import { useTranslation } from 'react-i18next';

type MenuProps = {
    handleClick: (detailView: DetailView) => void,
    open: boolean
}

export default function Menu({ handleClick }: MenuProps) {
    const { t } = useTranslation();
    const [selectedItem, setSelectedItem] = useState<DetailView>(DetailView.Notification)

    return (
        <ul className={`${style.menu}`}>
            <MenuItem
                name={t('settings_menu.notifications')}
                detailView={DetailView.Notification}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.password')}
                detailView={DetailView.Password}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.mail')}
                detailView={DetailView.Mail}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.affiliate')}
                detailView={DetailView.Affiliate}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.billing')}
                detailView={DetailView.Billing}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.networks')}
                detailView={DetailView.Networks}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.blocked')}
                detailView={DetailView.Blocked}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
            <MenuItem
                name={t('settings_menu.close')}
                detailView={DetailView.Account}
                selected={selectedItem}
                onSelection={selectionHandler}
            />
        </ul>
    )

    function selectionHandler(details: DetailView) {
        handleClick(details)
        setSelectedItem(details)
    }
}
