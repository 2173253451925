import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputButton from "../../../../../../../../theme/component/input-button";
import style from './image-cropper.module.scss';
import { ButtonColor } from "../../../../../../../../theme/component/input-button/models/button-color.model";
import ScaleIcon from '../../../../../../../../theme/resource/reactIcons/scale';

interface ImageCropperProps {
    imageUrl: string;
    imageType?: 'profile' | 'banner';
    onCrop: (croppedImage: File, cropData: { x: number, y: number, width: number, height: number }) => void;
    onCancel: () => void;
}

const ImageCropper: React.FC<ImageCropperProps> = ({ imageUrl, imageType, onCrop, onCancel }) => {
    const [cropBoxPosition, setCropBoxPosition] = useState({ x: 0, y: 0 });
    const [cropBoxSize, setCropBoxSize] = useState({ width: 0, height: 0 });
    const [imageSize, setImageSize] = useState({ width: 0, height: 0 });
    const imageRef = useRef<HTMLImageElement>(null);
    const cropBoxRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    const aspectRatio = imageType === 'banner' ? 800 / 190 : 1;

    useEffect(() => {
        const img = new Image();
        img.onload = () => {
            if (containerRef.current) {
                const containerWidth = containerRef.current.offsetWidth;
                const containerHeight = containerRef.current.offsetHeight;
                const imageAspectRatio = img.width / img.height;
                let newImageWidth, newImageHeight;

                if (imageAspectRatio > containerWidth / containerHeight) {
                    newImageWidth = containerWidth;
                    newImageHeight = containerWidth / imageAspectRatio;
                } else {
                    newImageHeight = containerHeight;
                    newImageWidth = containerHeight * imageAspectRatio;
                }

                setImageSize({ width: newImageWidth, height: newImageHeight });

                let newCropBoxWidth, newCropBoxHeight;
                if (imageType === 'banner') {
                    newCropBoxWidth = Math.min(newImageWidth, containerWidth * 0.9);
                    newCropBoxHeight = newCropBoxWidth / aspectRatio;
                } else {
                    newCropBoxWidth = Math.min(newImageWidth, newImageHeight, containerWidth * 0.9, containerHeight * 0.9);
                    newCropBoxHeight = newCropBoxWidth;
                }

                setCropBoxSize({ width: newCropBoxWidth, height: newCropBoxHeight });
                setCropBoxPosition({
                    x: (newImageWidth - newCropBoxWidth) / 2,
                    y: (newImageHeight - newCropBoxHeight) / 2
                });
            }
        };
        img.src = imageUrl;
    }, [imageUrl, imageType, aspectRatio]);

    const handleCrop = () => {
        if (imageRef.current && cropBoxRef.current) {
            const image = imageRef.current;
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            if (ctx) {
                const scaleX = image.naturalWidth / imageSize.width;
                const scaleY = image.naturalHeight / imageSize.height;

                canvas.width = cropBoxSize.width;
                canvas.height = cropBoxSize.height;

                ctx.drawImage(
                    image,
                    cropBoxPosition.x * scaleX,
                    cropBoxPosition.y * scaleY,
                    cropBoxSize.width * scaleX,
                    cropBoxSize.height * scaleY,
                    0,
                    0,
                    cropBoxSize.width,
                    cropBoxSize.height
                );

                canvas.toBlob((blob) => {
                    if (blob) {
                        const croppedFile = new File([blob], 'cropped_image.jpg', { type: 'image/jpeg' });
                        onCrop(croppedFile, {
                            x: cropBoxPosition.x * scaleX,
                            y: cropBoxPosition.y * scaleY,
                            width: cropBoxSize.width * scaleX,
                            height: cropBoxSize.height * scaleY
                        });
                    }
                }, 'image/jpeg');
            }
        }
    };

    const onMoveStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        const cropBox = cropBoxRef.current;
        if (!cropBox) return;

        let startX: number, startY: number;
        if ('touches' in e) {
            startX = e.touches[0].clientX - cropBoxPosition.x;
            startY = e.touches[0].clientY - cropBoxPosition.y;
        } else {
            startX = e.clientX - cropBoxPosition.x;
            startY = e.clientY - cropBoxPosition.y;
        }

        const onMove = (e: MouseEvent | TouchEvent) => {
            let clientX: number, clientY: number;
            if ('touches' in e) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }

            let newX = clientX - startX;
            let newY = clientY - startY;

            newX = Math.max(0, Math.min(newX, imageSize.width - cropBoxSize.width));
            newY = Math.max(0, Math.min(newY, imageSize.height - cropBoxSize.height));

            setCropBoxPosition({ x: newX, y: newY });
        };

        const onEnd = () => {
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onEnd);
            document.removeEventListener('touchmove', onMove);
            document.removeEventListener('touchend', onEnd);
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onEnd);
        document.addEventListener('touchmove', onMove, { passive: false });
        document.addEventListener('touchend', onEnd);
    }, [cropBoxPosition, cropBoxSize, imageSize]);

    const onResizeStart = useCallback((e: React.MouseEvent | React.TouchEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!cropBoxRef.current) return;

        let startX: number, startY: number;
        if ('touches' in e) {
            startX = e.touches[0].clientX;
            startY = e.touches[0].clientY;
        } else {
            startX = e.clientX;
            startY = e.clientY;
        }

        const startWidth = cropBoxSize.width;
        const startHeight = cropBoxSize.height;

        const onMove = (e: MouseEvent | TouchEvent) => {
            let clientX: number, clientY: number;
            if ('touches' in e) {
                clientX = e.touches[0].clientX;
                clientY = e.touches[0].clientY;
            } else {
                clientX = e.clientX;
                clientY = e.clientY;
            }

            const widthDiff = clientX - startX;
            const heightDiff = clientY - startY;
            const sizeDiff = Math.max(widthDiff, heightDiff);

            const newWidth = Math.max(50, Math.min(startWidth + sizeDiff, imageSize.width - cropBoxPosition.x));
            const newHeight = imageType === 'banner' ? newWidth / aspectRatio : newWidth;

            if (newHeight <= imageSize.height - cropBoxPosition.y) {
                setCropBoxSize({ width: newWidth, height: newHeight });
            } else {
                const constrainedHeight = imageSize.height - cropBoxPosition.y;
                const constrainedWidth = imageType === 'banner' ? constrainedHeight * aspectRatio : constrainedHeight;
                setCropBoxSize({ width: constrainedWidth, height: constrainedHeight });
            }
        };

        const onEnd = () => {
            document.removeEventListener('mousemove', onMove);
            document.removeEventListener('mouseup', onEnd);
            document.removeEventListener('touchmove', onMove);
            document.removeEventListener('touchend', onEnd);
        };

        document.addEventListener('mousemove', onMove);
        document.addEventListener('mouseup', onEnd);
        document.addEventListener('touchmove', onMove, { passive: false });
        document.addEventListener('touchend', onEnd);
    }, [cropBoxPosition, cropBoxSize, imageSize, imageType, aspectRatio]);

    const getResizeHandleStyle = () => {
        if (imageType === 'profile') {
            const radius = cropBoxSize.width / 2;
            const angle = Math.PI / 4; // 45 degrees
            return {
                left: `${radius + radius * Math.cos(angle) - 10}px`,
                top: `${radius + radius * Math.sin(angle) - 10}px`,
                cursor: 'nwse-resize'
            };
        }
        return {
            right: '-10px',
            bottom: '-10px',
            cursor: 'se-resize'
        };
    };

    return (
        <div className={style.modalOverlay}>
            <div className={style.modal}>
                <h2 className={style.modalTitle}>Crop {imageType === 'profile' ? 'Profile Picture' : 'Banner'}</h2>
                <div
                    className={style.imageContainer}
                    ref={containerRef}
                    style={{
                        width: '100%',
                        height: '60vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'hidden',
                        backgroundColor: '#f0f0f0',
                    }}
                >
                    <div style={{
                        position: 'relative',
                        width: `${imageSize.width}px`,
                        height: `${imageSize.height}px`,
                    }}>
                        <img
                            ref={imageRef}
                            src={imageUrl}
                            alt="Selected"
                            className={style.selectedImage}
                            style={{
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                display: 'block',
                            }}
                        />
                        <div
                            ref={cropBoxRef}
                            className={style.cropBox}
                            style={{
                                position: 'absolute',
                                width: `${cropBoxSize.width}px`,
                                height: `${cropBoxSize.height}px`,
                                left: cropBoxPosition.x,
                                top: cropBoxPosition.y,
                                borderRadius: imageType === 'profile' ? '50%' : '0',
                                boxShadow: '0 0 0 9999px rgba(0, 0, 0, 0.5)',
                                border: '2px solid #fff',
                            }}
                            onMouseDown={onMoveStart}
                            onTouchStart={onMoveStart}
                        >
                            <div
                                className={style.resizeHandle}
                                style={{
                                    ...getResizeHandleStyle(),
                                    position: 'absolute',
                                    width: '20px',
                                    height: '20px',
                                    backgroundColor: '#fff',
                                    borderRadius: '50%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    boxShadow: '0 0 6px rgba(0, 0, 0, 0.5)',
                                }}
                                onMouseDown={onResizeStart}
                                onTouchStart={onResizeStart}
                            >
                                <ScaleIcon size={16}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={style.modalFooter}>
                    <InputButton label="Cancel" color={ButtonColor.Failure} onClick={onCancel} />
                    <InputButton label="Upload Image" onClick={handleCrop} />
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;