import React from 'react';

type InboxIconProps = {
    fill?: string;
};

export function InboxIcon({ fill = 'rgb(54, 54, 54)' }: InboxIconProps) {

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2}}
        >
            <rect id="inbox" x="0" y="0" width="64" height="64" style={{fill: 'none'}}/>
            <g id="inbox1">
                <path
                    id="ion-chatboxes"
                    d="M11.619,53.135L9.765,53.136C6.153,53.136 3.096,50.912 3.096,47.437L3.096,29.512C3.096,26.038 5.874,23.258 9.487,23.258L9.765,23.258L9.765,37.294C9.765,41.88 13.796,45.492 18.659,45.492L34.917,45.492C35.737,45.492 37.28,46.187 37.28,46.187L40.893,48.687C40.337,51.607 36.862,53.134 33.668,53.134L23.015,53.134C22.449,53.24 22.112,53.413 21.938,53.587C21.241,54.932 20.288,56.146 19.263,57.261C18.319,58.286 17.206,59.155 16.069,59.962C15.177,60.594 14.209,61.127 13.205,61.56C12.675,61.788 12.04,62.162 11.515,61.924C11.175,61.769 11.458,61.168 11.538,60.803C11.715,59.998 12.109,59.252 12.281,58.448C12.666,56.646 13.087,54.681 11.619,53.135Z"
                    style={{fill: fill, fillRule: 'nonzero'}} // Füllfarbe dynamisch
                />
                <path
                    id="ion-chatboxes1"
                    d="M52.149,2C57.013,2 60.904,5.753 60.904,10.337L60.904,33.681C60.904,38.267 57.013,42.017 52.149,42.017L49.786,42.017C48.195,43.579 48.627,45.592 49.02,47.434C49.192,48.239 49.586,48.986 49.762,49.789C49.844,50.154 50.126,50.756 49.786,50.91C49.262,51.149 48.625,50.774 48.097,50.547C47.092,50.113 46.124,49.581 45.232,48.948C44.095,48.141 42.983,47.273 42.038,46.247C41.012,45.132 40.06,43.918 39.363,42.574C39.086,42.296 38.39,42.017 37,42.017L22.964,42.017C18.1,42.017 14.21,38.265 14.21,33.681L14.21,10.337C14.21,5.75 17.128,2 21.991,2L52.149,2ZM38.18,33.565C38.18,31.753 36.709,30.282 34.897,30.282L23.027,30.282C21.215,30.282 19.744,31.753 19.744,33.565C19.744,35.376 21.215,36.847 23.027,36.847L34.897,36.847C36.709,36.847 38.18,35.376 38.18,33.565ZM52.089,22.071C52.089,20.259 50.618,18.789 48.806,18.789L23.027,18.789C21.215,18.789 19.744,20.259 19.744,22.071C19.744,23.883 21.215,25.354 23.027,25.354L48.806,25.354C50.618,25.354 52.089,23.883 52.089,22.071ZM43.8,10.578C43.8,8.766 42.329,7.295 40.517,7.295L23.027,7.295C21.215,7.295 19.744,8.766 19.744,10.578C19.744,12.39 21.215,13.861 23.027,13.861L40.517,13.861C42.329,13.861 43.8,12.39 43.8,10.578Z"
                    style={{fill: fill, fillRule: 'nonzero'}} // Füllfarbe dynamisch
                />
            </g>
        </svg>);
}

