import React from 'react';
import style from './User.module.scss';
import {ReferralModel} from "../../../../../../../../../../service/settings/model/affiliate-settings.model";

type UserProps = {
    referredUser: ReferralModel,
}

export function User({ referredUser }: UserProps) {

    return (
        <div className={style.user_item}>
            <img src="profile1.jpg" alt="User Profile" className={style.profile_pic} />
            <div className={style.user_info}>
                <span className={style.username}>{referredUser.username}</span>
                <span className={style.returned_amount}>{referredUser.returned}</span>
            </div>
        </div>
    )
}