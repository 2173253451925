
import React, { useEffect, useState } from 'react'
import {Config} from '../../../../../../service/config/config'
import imageService from '../../../../../../service/image/image.service'
import { ImageType } from '../../../../../../service/image/models/image-type.model'
import ImageContent from '../../../../../../theme/component/legacy/image-content-legacy/image-content.component'
import ImageUpload from '../image-upload/image-upload.component'

import style from './avatar.module.scss'
import Overlay from "../../../../../../theme/component/overlay/overlay.component";

const defaultAvatar = require('../../../../../../theme/resource/image/default/avatar_default.png')

type AvatarProps = {
    editingMode: boolean
    avatarUrl: string
    className?: string
}

export default function Avatar({ editingMode, avatarUrl, className }: AvatarProps) {
    const [avatar, setAvatar] = useState<string>('')
    const [expanded, setExpanded] = useState<boolean>(false)

    useEffect(() => {
        const banner = avatarUrl ? Config.host + avatarUrl : defaultAvatar
        setAvatar(banner)
    }, [avatarUrl])


    async function uploadImage(file: File) {
        try {
            const newBanner =  await imageService.uploadImage(file, ImageType.Avatar)
            console.log(Config.host + newBanner.url)
            setAvatar(Config.host + newBanner.url)
        } catch (err) {
            console.log(err)
        }
    }
    
    function onExpandAvatar() {
        setExpanded(!expanded && !editingMode)
    }

    return (
        <span onClick={onExpandAvatar} className={`${style.avatar} ${className}`}>
        { expanded && <Overlay onClose={() => setExpanded(false)} open={expanded} title={''} className={style.expanded_overlay}>
            <ImageContent className={style.avatar_image} src={avatar} alt='Profile Avatar' />
        </Overlay>}
        <ImageContent className={style.avatar_image} src={avatar} alt='Profile Avatar' />
        <ImageUpload editingMode={editingMode} uploadImage={uploadImage} imageType={'profile'} />
    </span>
    )

    
}
