import React from 'react';
import style from './index.module.scss';
import { Receipient } from './model/receipient.model';
import ChatMessage from '../../../../../../service/chat/model/ChatMessage';
import moment from 'moment';
import Datestamp from '../datestamp';
import Unreadbarrier from '../unreadbarrier';
import { OfferRef } from "./component/offer-reference";
import {Config} from "../../../../../../service/config/config";

type MessageProps = {
    receipient: Receipient;
    message: ChatMessage;
    showDatestamp?: boolean;
    unreadbarrier?: boolean;
};

const defaultAvatar = "UH6k|=oyozj[%%ogogkC%ij]fRj[yFofaej]"; // Your default avatar string

const renderContent = (content: string, receipient: Receipient): JSX.Element[] => {
    const parts = content.split(/(\[img\].*?\[\/img\])/);
    return parts.map((part, index) => {
        if (part.startsWith('[img]') && part.endsWith('[/img]')) {
            const imageRef = part.slice(5, -6);
            const imageUrl = Config.host + '/api/image?ref='+ imageRef ;
            return (
                <div key={index} className={style.imageContainer}>
                    <img src={imageUrl} alt="Uploaded" className={style.uploadedImage} />
                </div>
            );
        } else {
            return (
                <p key={index} className={style.content}>
                    {linkify(part, receipient)}
                </p>
            );
        }
    });
};

const linkify = (text: string, receipient: Receipient): (string | JSX.Element)[] => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
        if (part.match(urlRegex)) {
            const linkClass = receipient === Receipient.Sender ? style.senderLink : style.receiverLink;
            return <a key={index} href={part} target="_blank" rel="noopener noreferrer" className={linkClass}>{part}</a>;
        }
        return part;
    });
};

export default function Message({ message, receipient, showDatestamp, unreadbarrier }: MessageProps) {
    const renderedContent = renderContent(message.content, receipient);

    const renderedDatestamp = showDatestamp ? <Datestamp date={message.date} /> : <></>;
    const renderedUnreadbarrier = unreadbarrier ? <Unreadbarrier /> : <></>;

    return (
        <>
            {renderedDatestamp}
            {renderedUnreadbarrier}
            <div className={`${style[receipient]} ${style.message}`}>
                <span className={style.bubble}>
                    <OfferRef offer={message.offer} receipient={receipient} />
                    {renderedContent}
                    <span className={style.timestamp}>{moment(message?.date).format('HH:mm')}</span>
                </span>
            </div>
        </>
    );
}