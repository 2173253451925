import React from 'react';

type IconProps = {
    fill?: string;
};

export function ExploreIcon({ fill = 'rgb(54, 54, 54)' }: IconProps) {

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
            <rect id="explore" x="0" y="0" width="64" height="64" style={{ fill: 'none' }} />
            <g id="explore1">
                {/* Erster Pfad */}
                <path
                    d="M43.784,17.127L26.765,24.905C25.942,25.281 25.281,25.942 24.905,26.765L17.127,43.784C16.229,45.748 18.251,47.771 20.216,46.873L37.235,39.095C38.058,38.719 38.719,38.058 39.095,37.235L46.873,20.216C47.771,18.251 45.749,16.229 43.784,17.127ZM34.662,34.662C33.192,36.133 30.808,36.133 29.338,34.662C27.867,33.192 27.867,30.808 29.338,29.338C30.808,27.867 33.192,27.867 34.662,29.338C36.133,30.808 36.133,33.192 34.662,34.662Z"
                    style={{ fill: fill }} // Füllfarbe dynamisch
                />

                {/* Zweiter Pfad */}
                <path
                    d="M32,2C48.557,2 62,15.443 62,32C62,48.557 48.557,62 32,62C15.443,62 2,48.557 2,32C2,15.443 15.443,2 32,2ZM32,9.5C44.418,9.5 54.5,19.582 54.5,32C54.5,44.418 44.418,54.5 32,54.5C19.582,54.5 9.5,44.418 9.5,32C9.5,19.582 19.582,9.5 32,9.5Z"
                    style={{ fill: fill }} // Füllfarbe dynamisch
                />
            </g>
        </svg>
    );
}