import axios from 'axios'
import PasswordChangedResponse from './model/passwort-change.model'
import ISettings from './model/settings.model'
import AccountSettingsResponse from './model/account-settings.model'
import {NotificationSettingsModel} from "./model/notification-settings.model";
import {InquerySettingsModel} from "./model/inquery-settings.model";


class SettingsService {

    private root = '/settings'
    private client = axios

    public async changePassword(password: string, currentPassword: string): Promise<PasswordChangedResponse> {
        try {
            const response = await this.client.post(`${this.root}/password/change`, {
                newPassword: password,
                currentPassword: currentPassword
             })
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

    public async forgotPassword(mail: string): Promise<boolean> {
        try {
            const response = await this.client.post(`${this.root}/password/forgot`, {
                mail: mail
             })
             return response?.data.sendRecoveryMail
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data.sendRecoveryMail
            }
        }
    }

    public async setNotificationSettings(mail: string, notificationSettings: NotificationSettingsModel): Promise<void> {
        await this.client.post(`${this.root}/notifications`, { notification: notificationSettings, mail})
    }

    public async getSettings() : Promise<ISettings> {
        try {
            const response = await this.client.get(`${this.root}/`)
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

    public async getNotificationSettings(): Promise<InquerySettingsModel> {
        try {
            const response = await this.client.get(`${this.root}/notification/`)
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

    public async setInquerySettings(inquerySettings: InquerySettingsModel): Promise<InquerySettingsModel> {
        try {
            const response = await this.client.post(`${this.root}/inquery/`, inquerySettings)
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

    public async getInquerySettings(): Promise<InquerySettingsModel> {
        try {
            const response = await this.client.get(`${this.root}/inquery/`)
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

    public async setAccountData(username: string, mail: string): Promise<AccountSettingsResponse> {
        const response = await this.client.post(`${this.root}/account/`, {username, mail})
        return response?.data
    }

    public async closeAccount(): Promise<AccountSettingsResponse> {
        try {
            const response = await this.client.post(`${this.root}/account/close`)
             return response?.data
        } catch(error) {
            if (error.response.status === 400) {
                return error.response.data
            }
        }
    }

}

export default new SettingsService()