import { Capacitor } from "@capacitor/core";
import { PushNotifications } from "@capacitor/push-notifications";
import axios from "axios";
import SessionService from "../session/session.service";

class PushNotificationService {
    private client = axios;
    private apiRoot = '/apns';

    public async setup() {
        console.log('SETUP!')
        if (Capacitor.getPlatform() !== 'ios') return;
        if (!SessionService.getSessionDetails().authenticated) return;

        const result = await PushNotifications.requestPermissions();
        console.log("RESULTS: ", result)
        if (result.receive === 'granted') {
            await this.setupListeners();
            await PushNotifications.register();
        }
    }

    private async setupListeners() {
        console.log('LISTENERS!')

        await PushNotifications.addListener('registration', (token) => {
            console.log('Token registered')
            this.sendTokenToBackend(token.value);
        });

        await PushNotifications.addListener('registrationError', (error) => {
            console.log('Registration error:');
        });

        await PushNotifications.addListener('pushNotificationReceived', (notification) => {
            console.log('Push notification received:', notification);
        });

        await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
            console.log('Push notification action performed:', notification);
        });
    }

    private async sendTokenToBackend(token: string) {
        try {
            const response = await this.client.post(`${this.apiRoot}/token`, { token });
            console.log('Token saved:', response.data);
        } catch (error) {
            console.error('Error sending token:', error);
        }
    }
}

export default new PushNotificationService();
