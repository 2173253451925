import { useCallback, useEffect, useState } from 'react';

// Adding a type for the callback function for clarity
type CallbackFunction = () => void;

export const useInfiniteScroll = (
    callback: CallbackFunction,
    isLoading: boolean,
    direction: 'up' | 'down' = 'down'
): [boolean, string, string] => {
    const [lastScrollTop, setLastScrollTop] = useState(0);
    const [scrollDirection, setScrollDirection] = useState('down');

    const handleScroll = useCallback(() => {
        // Normalize scroll position calculation
        const currentScrollPosition = Math.max(
            window.pageYOffset,
            document.documentElement.scrollTop,
            document.body.scrollTop
        );

        // Calculate threshold using scrollHeight instead of offsetHeight
        const threshold = document.documentElement.scrollHeight - window.innerHeight - 500;

        // Detect scroll direction
        if (currentScrollPosition > lastScrollTop) {
            setScrollDirection('down');
        } else {
            setScrollDirection('up');
        }

        // Update last scroll position
        setLastScrollTop(currentScrollPosition);

        // Adjust condition for triggering the callback
        if (currentScrollPosition >= threshold && !isLoading && scrollDirection === 'down') {
            callback();
        }
    }, [callback, isLoading, lastScrollTop, scrollDirection]);

    useEffect(() => {
        const handleDebouncedScroll = () => {
            requestAnimationFrame(handleScroll);
        };

        // Attach a debounced version of handleScroll
        window.addEventListener('scroll', handleDebouncedScroll);
        return () => window.removeEventListener('scroll', handleDebouncedScroll);
    }, [handleScroll]);

    return [isLoading, scrollDirection, direction];
};
