import React from "react";
import style from "./DashboardUser.module.scss";
import {
    AffiliateSettingsModel,
    ReferralModel
} from "../../../../../../../../service/settings/model/affiliate-settings.model";
import {User} from "./component/user/User";

type DashboardUserProps = {
    affiliateSettings: AffiliateSettingsModel;
}

export function DashboardUser({ affiliateSettings }: DashboardUserProps) {

    const referrals: ReferralModel[] = affiliateSettings.referrals;

    if (referrals.length === 0) {
        return (
            <div className={style.user_list}>
                <h2 className={style.title}>Referred Users</h2>
                <div className={style.no_users}>No referred users yet</div>
            </div>
        );
    }

    const user = referrals.map((referral, index) => {
        return <User referredUser={referral} />;
    });

    return (
        <>
            <h2 className={style.title}>Referred Users</h2>
            <div className={style.user_list}>{user}</div>
        </>
    );
}