import React from 'react'
import Menu from './component/menu/Menu'
import Search from './component/search/search.component'
import User from './component/user/user.component'
import Logo from './component/logo/logo.component'

import style from './navbar.module.scss'

type NavbarProps = {
  loggedIn: boolean
}

export default function Navbar({ loggedIn }: NavbarProps) {
  return (
      <div className={style.safe_area}>
          <div className={`${style.navbar} protected`}>
              <Logo />
              <Search />
              <Menu />
              <User loggedIn={loggedIn} />
          </div>
      </div>
  )
}