import { AxiosInstance } from 'axios'
import {Config} from '../config/config'
import SessionDetails from './model/session-details'
import MeDetails from "./model/me-details";

const axios = require('axios')

class SessionService {

    private sessionDetails: SessionDetails
    private meDetails: MeDetails
    private client: AxiosInstance

    constructor () {
        this.sessionDetails = {
            token: localStorage.getItem('token'),
            username: localStorage.getItem('username'),

            authenticated: false,
            authorized: false,
            active: false,
            premium: false
        }
    }

    public async validateSession(): Promise<SessionDetails> {
        const response = await axios.get(
            `${Config.apiRoot}/session/validate`,
            { headers: { Authorization: `Token ${this.sessionDetails?.token}` } })

        const session = response.data as SessionDetails

        this.setSessionDetails(session)

        await this.validateMe()

        return this.sessionDetails
    }

    public async validateMe(): Promise<MeDetails> {
        const response = await axios.get(
            `${Config.apiRoot}/session/me`,
            { headers: { Authorization: `Token ${this.sessionDetails?.token}` } })
        const meDetails = response.data as MeDetails

        this.setMeDetails(meDetails)

        return meDetails
    }
    setMeDetails(meDetails: MeDetails) {
        this.meDetails = meDetails
    }

    setSessionDetails(sessionDetails: SessionDetails) {
        this.sessionDetails = sessionDetails
        this.persistToken(sessionDetails?.token)
        this.persistUsername(sessionDetails?.username)

        this.fireSessionDetailEvent(this.sessionDetails)
    }

    getSessionDetails(): SessionDetails {
        return this.sessionDetails
    }

    private persistToken(token: string) {
        axios.defaults.headers.common = { Authorization: `token ${token}` }
        localStorage.setItem('token', token)
    }

    getToken(): string {
        return this.sessionDetails?.token
    }

    private persistUsername(username: string) {
        localStorage.setItem('username', username)
    }

    getUsername(): string {
        return this.sessionDetails?.username
    }

    isAuthenticated(): boolean {
        return this.sessionDetails?.authenticated
    }

    isActive(): boolean {
        return this.sessionDetails?.active
    }

    isAuthorized(): boolean {
        return this.sessionDetails.authorized
    }

    async signOut(): Promise<void> {
        const session: SessionDetails = {
            token: this.sessionDetails?.token,
            username: '',
            
            authenticated: false,
            authorized: false,
            active: false,
            premium: false
        }

        this.setSessionDetails(session)
    }

    private fireSessionDetailEvent(session: SessionDetails) {
        const event = new CustomEvent<SessionDetails>('session', { detail: session })

        document.dispatchEvent(event)
    }

    getMeDetails() {
        return this.meDetails
    }
}

export default new SessionService() as SessionService