import React, { useState, useEffect, useContext, useRef } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import style from './menu.module.scss';
import { useTranslation } from "react-i18next";
import HasMessagesContext from "../../../../../state/context/has-messages";
import { InboxIcon } from "../../../../icon/InboxIcon";
import { MarketplaceIcon } from "../../../../icon/MarketplaceIcon";
import { ExploreIcon } from "../../../../icon/google-icon/ExploreIcon";
import { Option } from "./component/Option";
import { MobileOption } from "./component/MobileOption";

export default function Menu() {
    const [quickactionsOpen, setQuickactionsOpen] = useState(true);
    const hasMessagesContext = useContext(HasMessagesContext);
    if (!hasMessagesContext) throw new Error("HasMessagesContext must be used within a HasMessagesContextProvider");
    const { hasNewMessages } = hasMessagesContext;

    const quickactionRef = useRef();
    const location = useLocation();
    const { t } = useTranslation();

    const [currentPage, setCurrentPage] = useState('');

    useEffect(() => {
        const path = location.pathname;
        if (path === '/') {
            setCurrentPage('home');
        } else if (path.includes('/explore')) {
            setCurrentPage('explore');
        } else if (path.includes('/inbox')) {
            setCurrentPage('inbox');
        } else if (path.includes('/marketplace')) {
            setCurrentPage('marketplace');
        }
    }, [location]);

    return (
        <div className={style.menuWrapper}>
            <span className={style.desktopMenu}>
                <span className={`${style.quickactions} ${quickactionsOpen ? '' : style.closed}`} ref={quickactionRef}>
                    <Option selected={currentPage === 'explore'}
                            icon={ExploreIcon}
                            linkTo={'/explore'}
                            tooltip={t('menu.explore')} />
                    <Option selected={currentPage === 'inbox'}
                            hasNewMessages={hasNewMessages}
                            icon={InboxIcon}
                            linkTo='/inbox'
                            tooltip={t('menu.chats')} />
                    <Option selected={currentPage === 'marketplace'}
                            icon={MarketplaceIcon}
                            linkTo={'/marketplace'}
                            tooltip={t('menu.marketplace')} />
                </span>
            </span>
            <div className={style.mobileMenu}>
                <MobileOption selected={currentPage === 'explore'}
                              icon={ExploreIcon}
                              linkTo='/explore'
                              name={t('menu.explore')} />
                <MobileOption selected={currentPage === 'inbox'}
                              icon={InboxIcon}
                              linkTo='/inbox'
                              name={t('menu.chats')} />
                <MobileOption selected={currentPage === 'marketplace'}
                              icon={MarketplaceIcon}
                              linkTo='/marketplace'
                              name={t('menu.marketplace')} />
            </div>
        </div>
    );
}
