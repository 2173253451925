import React, {useEffect, useState} from 'react';
import style from './AffiliateSettings.module.scss';
import ISettings from '../../../../../../service/settings/model/settings.model';
import {useTranslation} from 'react-i18next';
import {Config} from "../../../../../../service/config/config";
import SessionService from "../../../../../../service/session/session.service";
import {DashboardMeta} from "./component/dashboard-meta/DashboardMeta";
import {DashboardUser} from "./component/dashboard-user/DashboardUser";
import {DashboardHeader} from "./component/dashboard-header/DashboardHeader";
import {ReferralModel} from "../../../../../../service/settings/model/affiliate-settings.model";

type AccountDetailsProps = {
    settings: ISettings;
    onSave: (settings: ISettings) => void;
};

export function AffiliateSettings({ settings, onSave }: AccountDetailsProps) {
    const { t } = useTranslation();
    const [openAccountCloseBox, setOpenAccountCloseBox] = useState(false);

    useEffect(() => {
        const affiliateSettings = settings.affiliate;
        const userSettings = affiliateSettings.referrals;


    }, []);

    return (
        <div className="mb2">
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>Affiliate Dashboard</div>
                    <div className={style.info}>View and withdraw your affiliate earnings here</div>
                </div>
            </div>
            <DashboardHeader affiliateSettings={settings.affiliate} />
            <DashboardMeta affiliateSettings={settings.affiliate} />
            <DashboardUser affiliateSettings={settings.affiliate} />
        </div>
    );
}
