import React, { useEffect, useState } from 'react'
import {Config} from '../../../../../../service/config/config'
import imageService from '../../../../../../service/image/image.service'
import { ImageType } from '../../../../../../service/image/models/image-type.model'
import ImageContent from '../../../../../../theme/component/legacy/image-content-legacy/image-content.component'
import ImageUpload from '../image-upload/image-upload.component'

import style from './banner.module.scss'
import profileService from "../../../../../../service/profile/profile.service";
import ProfileView from "../../../../../../service/profile/model/profile-view.model";
import {Bookmark} from "../../../../../../theme/component/bookmark";
import SessionService from "../../../../../../service/session/session.service";
import {ProfileType} from "../../../../../../service/marketplace/model/offer.model";

const defaultBanner = require('../../../../../../theme/resource/image/default/banner_default.png')

type BannerProps = {
    profile: ProfileView
    editingMode: boolean
}

export default function Banner({ profile, editingMode }: BannerProps) {
    const [banner, setBanner] = useState<string>('')
    const [bookmarked, setBookmarked] = useState<boolean>(undefined)

    useEffect(() => {
        const banner = profile.banner ? Config.host + profile.banner : defaultBanner
        setBookmarked(SessionService.getUsername() !== profile.username ? profile.bookmarked : undefined)
        setBanner(banner)
    }, [profile])


    async function uploadImage(file: File) {
        try {
            const newBanner = await imageService.uploadImage(file, ImageType.Banner)
            setBanner(Config.host + newBanner.url)
        } catch (err) {
            console.log(err)
        }
    }

    async function onBookmark() {
        try {
            await profileService.bookmarkProfile(profile.username)
            setBookmarked(!bookmarked)
        } catch (error) {
            console.log(error)
        }
    }

    function TypeTag({profileType}: { profileType: ProfileType }) {
        const isBrand = profileType === ProfileType.BRAND
        return <span className={`${style.type_tag} tooltip`} >
        <span className={isBrand ? `${style.type_tag_brand} tooltip` : `${style.type_tag_influencer} tooltip`} />
            <span className={style.type_tag_text}>{isBrand ? 'Brand' : 'Influencer'}</span>
    </span>
    }

    function BookmarkContainer({}:{}) {
        if (bookmarked === undefined) {
            return <></>
        }

        return <span className={style.bookmark_container}>
                    <div><Bookmark className={style.bookmark} onClick={onBookmark} enabled={bookmarked} /></div>
                </span>
    }
    return (
        <>
            <div className={style.banner}>
                <TypeTag profileType={profile.type}/>
                    <BookmarkContainer/>
                <ImageContent src={banner} alt='Profile Banner' />
                <ImageUpload editingMode={editingMode} uploadImage={uploadImage} useEdge={true} imageType={'banner'}/>
            </div>
        </>
    )
}