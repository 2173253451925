import React, { useEffect, useRef, useState } from 'react'
import Menu from './component/settings-menu/settings-menu.component'

import style from './settings.module.scss'
import PasswordDetails from './component/settings-details/component/password-details/password-details.component'
import { DetailView } from './model/SettingViewsModel'
import AccountCloseDetails from './component/settings-details/component/account-details/account-details.component'
import NotificationDetails from './component/settings-details/component/notification-details/notification-details.component'
import TechnicalError from '../../components/app/component/error/technical/technical-error.component'
import LoadingContent from '../../theme/layout/loading-content/loading-content.component'
import settingsService from '../../service/settings/settings.service'
import ISettings from '../../service/settings/model/settings.model'
import BillingDetails from './component/settings-details/component/billing-details/billing-details.component'
import MailDetails from './component/settings-details/component/mail-details/mail-details.component'
import {useTranslation} from "react-i18next";
import NetworkDetails from "./component/settings-details/component/network-details";
import BlockedDetails from "./component/settings-details/component/blocked-details";
import {AffiliateSettings} from "./component/settings-details/component/affiliate-settings/AffiliateSettings";

function getViewComponent(detailView: DetailView) {
  switch (detailView) {
    case DetailView.Affiliate: return AffiliateSettings
    case DetailView.Account: return AccountCloseDetails;
    case DetailView.Notification: return NotificationDetails;
    case DetailView.Password: return PasswordDetails;
    case DetailView.Billing: return BillingDetails;
    case DetailView.Mail: return MailDetails;
    case DetailView.Networks: return NetworkDetails;
    case DetailView.Blocked: return BlockedDetails;

    default: return AccountCloseDetails;
  }
}


export function Settings() {
  const [detailView, setDetailView] = useState<DetailView>(DetailView.Notification)
  const [getOpen, setOpen] = useState<boolean>(false)
  const [settings, setSettings] = useState<ISettings>()

  const [notFound, setNotFound] = useState<boolean>(false)
  const [serversideError, setServersideError] = useState<boolean>(false)

  const {t} = useTranslation()


  useEffect(() => {
    fetchSettings()

  }, [])

  async function fetchSettings() {
    try {
      const settings = await settingsService.getSettings()
      setSettings(settings)
    } catch (error) {
      const response = error.toJSON()
      if (response.status === 404) setNotFound(true)
      if (response.status >= 500) setServersideError(true)
    }
  }

  const handleClick = (details: DetailView) => {
    setDetailView(details)
    setOpen(!getOpen)
  }
  const menuRef = useRef(null)
  const detailRef = useRef(null)

  if (serversideError) return <TechnicalError />
  if (settings === undefined) return <LoadingContent />

  const DetailComponent = getViewComponent(detailView);

  return (
        <div className={style.container}>
          <span className={style.menu}>
            <Menu open={getOpen} handleClick={handleClick} />
          </span>
          <div className={style.content}>
            <span className={style.child_content}>
              <DetailComponent settings={settings} onSave={setSettings} />
            </span>
          </div>
        </div>
  );

       //  {/*<button className="toggle-btn" onClick="toggleContent()">Show Content</button>*/}

      // <div className={style.container}>
      //     {/*<h2 className="text-left ml1">Settings</h2> /!* Nutze hier dein Translation-Feature *!/*/}
      //         <Menu open={getOpen} handleClick={handleClick} />
      //         {/*<div className={style.backButton} onClick={() => setOpen(!getOpen)}>*/}
      //         {/*  /!* Back button for mobile view *!/*/}
      //         {/*  <span>Back to Menu</span>*/}
      //         {/*</div>*/}
      //         <DetailComponent settings={settings} onSave={setSettings} />
      // {/*// </div>*/}

}
