import axios from "axios";
import {ThirdPartyAuthorizationResponse} from "./model/third-party-authorization.response";

export class ThirdPartyAuthorizationService {

    private static rootUrl = '/account'
    private static client = axios

    static async getAuthorizeEndpoint(provider: string, referredBy?: string): Promise<string> {
        try {
            const response = await this.client.get(`${this.rootUrl}/authorize/oauth-endpoint`, {
                params: {
                    provider,
                    referredBy
                }
            })

            return response.data
        } catch(error) {
            console.log(error)
            return null
        }

    }

    static getAppleLoginRedirectUrl(): string {
        return undefined
    }

    static async authorize(provider: string, code: string, state: string, signal: AbortSignal): Promise<ThirdPartyAuthorizationResponse> {
        try {
            const response = await this.client.get(`${this.rootUrl}/authorize`,
                { signal, params: { provider, code, state } })

            const thirdPartyAuthorizationResponse = response?.data as ThirdPartyAuthorizationResponse
            console.log(thirdPartyAuthorizationResponse)

            return thirdPartyAuthorizationResponse
        } catch(error) {
            console.log(error)
            return null
        }
    }

}