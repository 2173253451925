import {ProductEnum} from "../model/ProductEnum";
import {IProduct} from "../model/IProduct";

export class ProductMapper {
    // Map mit allen Produktdetails
    private static productMap: Record<ProductEnum, IProduct> = {
        [ProductEnum.PREMIUM_MONTHLY]: {
            id: ProductEnum.PREMIUM_MONTHLY,
            title: 'Premium Monthly',
            description: 'Access premium features for a month',
            price: '10.90',
            type: 'paid_subscription',
        },
        [ProductEnum.PREMIUM_YEARLY]: {
            id: ProductEnum.PREMIUM_YEARLY,
            title: 'Premium Yearly',
            description: 'Access premium features for a year',
            price: '109',
            type: 'paid_subscription',
        },
        [ProductEnum.BRAND_MONTHLY]: {
            id: ProductEnum.BRAND_MONTHLY,
            title: 'Brand Access Monthly',
            description: 'Access brand features for a month',
            price: '12.90',
            type: 'paid_subscription',
        },
        [ProductEnum.BRAND_YEARLY]: {
            id: ProductEnum.BRAND_YEARLY,
            title: 'Brand Access Yearly',
            description: 'Access brand features for a year',
            price: '129',
            type: 'paid_subscription',
        },
    };

    /**
     * Gibt das Produkt basierend auf dem ProductEnum zurück.
     * @param productEnum Enum für das gewünschte Produkt
     * @returns Produktdetails
     */
    static getProduct(productEnum: ProductEnum): IProduct | null {
        return this.productMap[productEnum] || null;
    }

    /**
     * Gibt alle registrierten Produkte zurück.
     * @returns Liste aller Produkte
     */
    static getAllProducts(): IProduct[] {
        return Object.values(this.productMap);
    }
}
