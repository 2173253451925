import React, {useState} from "react";
import style from "./WithdrawPopup.module.scss";
import {BillingDetailsModel} from "../../../../../../../../../../service/settings/model/affiliate-settings.model";
import {NotificationService} from "../../../../../../../../../../service/notification/notification.service";
import {NotificationType} from "../../../../../../../../../../service/notification/model/notification-type.enum";
import {AffiliateSettingsService} from "../../../../../../../../../../service/settings/AffiliateSettings.service";
import Overlay from "../../../../../../../../../../theme/component/overlay/overlay.component";

type WithdrawPopupProps = {
    showWithdraw: boolean;
    setShowWithdraw: (showWithdraw: boolean) => void;
}

export function WithdrawPopup({ showWithdraw, setShowWithdraw }: WithdrawPopupProps) {

    const [disableBackdropClose, setDisableBackdropClose] = useState(false);

    const [billingDetails, setBillingDetails] = useState<BillingDetailsModel>({
        bankingIban: '',
        surname: '',
        lastname: '',
        street: '',
        houseNumber: '',
        zipCode: '',
        city: '',
        country: '',
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setBillingDetails({
            ...billingDetails,
            [name]: value,
        });
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        try {
            const response = await AffiliateSettingsService.withdrawEarnings(billingDetails);

            console.log('RESPONE', response?.data);

            NotificationService.displayNotification({
                message: "Withdrawal request successful!",
                type: NotificationType.success
            })
        } catch(error) {
            NotificationService.displayNotification({
                message: error?.response?.data?.description,
                type: NotificationType.failure
            })
        }
    };

    return (
        <Overlay title='Billing Details' open={showWithdraw} onClose={() => setShowWithdraw(false)} disableTapout={disableBackdropClose}>
                <form className={style.form} onSubmit={handleSubmit}>
                    <label>
                        IBAN:
                        <input type="text"
                               name="bankingIban"
                               value={billingDetails.bankingIban}
                               onChange={handleChange}
                               placeholder="Bank Information"
                               required />
                    </label>
                    <label>
                        First Name:
                        <input type="text"
                               name="surname"
                               value={billingDetails.surname}
                               onChange={handleChange}
                               placeholder="Surname"
                               required />
                    </label>
                    <label>
                        Last Name:
                        <input type="text"
                               name="lastname"
                               value={billingDetails.lastname}
                               onChange={handleChange}
                               placeholder="Lastname"
                               required />
                    </label>
                    <label>
                        Street:
                        <input type="text"
                               name="street"
                               value={billingDetails.street}
                               onChange={handleChange}
                               placeholder="Street"
                               required />
                    </label>
                    <label>
                        House Number:
                        <input type="text"
                               name="houseNumber"
                               value={billingDetails.houseNumber}
                               onChange={handleChange}
                               placeholder="House Number"
                               required />
                    </label>
                    <label>
                        ZIP Code:
                        <input type="text"
                               name="zipCode"
                               value={billingDetails.zipCode}
                               onChange={handleChange}
                               placeholder="ZIP Code"
                               required />
                    </label>
                    <label>
                        City:
                        <input type="text"
                               name="city"
                               value={billingDetails.city}
                               onChange={handleChange}
                               placeholder="City"
                               required />
                    </label>
                    <label>
                        Country:
                        <input type="text"
                               name="country"
                               value={billingDetails.country}
                               onChange={handleChange}
                               placeholder="Country"
                               required />
                    </label>
                    <div className={style.saveButton}>
                        <button type="submit">Submit</button>
                    </div>
                </form>
        </Overlay>
    );
}