import React from "react";
import {Link} from "react-router-dom";
import style from "../menu.module.scss";
import {conditionalClass} from "../../../../../../utility/conditionalClass.utility";

type OptionProps = {
    selected: boolean;
    hasNewMessages?: boolean; // Indikator für neue Nachrichten
    icon: React.ElementType; // Dynamisches Icon als Prop
    linkTo: string; // Dynamischer Link als Prop
    tooltip: string;
};

export function Option({
                    selected,
                    hasNewMessages,
                    icon: Icon, // Standard-Icon ist das InboxIcon, kann aber überschrieben werden
                    linkTo, // Standard-Link ist "/inbox", kann überschrieben werden
                    tooltip
                }: OptionProps) {
    return (
        <Link to={linkTo} className={`${style.item} tooltip`}>
            <span className={style.icon}>
                <Icon fill={selected ? "rgb(0, 83, 255)" : "rgb(54, 54, 54)"} />
            </span>

            {/* Indikator, wenn die Seite aktiv ist */}
            <div className={conditionalClass(selected, style.lineIndicator)}></div>

            {/* Dot für neue Nachrichten, wird nur angezeigt, wenn hasNewMessages true ist */}
            <span className={conditionalClass(hasNewMessages, style.inbox_dot)} />

            {/* Tooltip für mobile Ansicht */}
            <span className={`${style.mobiletooltip} tooltiptext`}>{tooltip}</span>
        </Link>
    );
};