import React from 'react'
import { useNavigate } from 'react-router-dom'
import {Config} from '../../../../../../service/config/config'

import style from './avatar.module.scss'

const defaultAvatar = require('../../../../../../theme/resource/image/default/avatar_default.png')

type AvatarProps = { 
    username: string,
    avatarUrl: string, 
    unread: number
}

export default function Avatar({ username, avatarUrl, unread }: AvatarProps) {
    const navigate = useNavigate()

    const avatar = avatarUrl ? Config.host + avatarUrl : defaultAvatar

    return (
        <span className={style.avatar} onClick={onAvatarClick}>
            <img src={avatar} alt='Profile Avatar'/>
            <UnreadMessages unread={unread}/>
        </span>
    )

    function onAvatarClick() {
        navigate(`/profile/${username}`)
    }
}

function UnreadMessages({unread}: {unread: number}) {
    if(unread < 1) {
        return (<></>)
    } else {
        return (
            <span className={style.unread_messages}>{unread}</span>
        )
    }
}