import axios from 'axios'
import PasswordChangedResponse from './model/passwort-change.model'
import ISettings from './model/settings.model'
import AccountSettingsResponse from './model/account-settings.model'
import {NotificationSettingsModel} from "./model/notification-settings.model";
import {InquerySettingsModel} from "./model/inquery-settings.model";
import {BillingDetailsModel} from "./model/affiliate-settings.model";


export class AffiliateSettingsService {

    private static root = '/affiliate'
    private static client = axios

    public static async withdrawEarnings(billingDetails: BillingDetailsModel): Promise<any> {
        return this.client.post(`${this.root}/withdraw`, billingDetails);
    }

}