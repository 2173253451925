import React from 'react';

type IconProps = {
    fill?: string;
};

export function MarketplaceIcon({ fill = 'rgb(54, 54, 54)' }: IconProps) {

    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 64 64"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlSpace="preserve"
            style={{ fillRule: 'evenodd', clipRule: 'evenodd', strokeLinejoin: 'round', strokeMiterlimit: 2 }}
        >
            <rect id="icon" x="0" y="0" width="64" height="64" style={{ fill: 'none' }} />

            {/* Erster Pfad */}
            <path
                d="M8.548,5.082L55.452,5.082L61.182,18.887L61.772,23.003C61.623,26.617 58.642,29.506 54.991,29.506C51.34,29.506 48.359,26.617 48.21,23.003L48.204,23.003L48.204,19L46.495,19L46.495,23.003C46.346,26.617 43.364,29.506 39.713,29.506C36.063,29.506 33.081,26.617 32.932,23.003L32.926,23.003L32.926,19L31.074,19L31.074,23.003L31.068,23.003C30.919,26.617 27.937,29.506 24.287,29.506C20.636,29.506 17.654,26.617 17.505,23.003L17.505,19L15.796,19L15.796,23.003L15.79,23.003C15.641,26.617 12.66,29.506 9.009,29.506C5.358,29.506 2.377,26.617 2.228,23.003L2.648,18.896L8.548,5.082ZM17.505,22.435L17.505,23.002C17.501,22.909 17.499,22.814 17.499,22.719C17.499,22.624 17.501,22.529 17.505,22.435ZM46.495,22.435L46.495,23.002C46.499,22.909 46.501,22.814 46.501,22.719C46.501,22.624 46.499,22.529 46.495,22.435Z"
                style={{ fill: fill }} // Füllfarbe dynamisch
            />

            {/* Zweiter Pfad */}
            <path
                d="M6.026,57.427L6.026,30.349C6.957,30.699 7.966,30.891 9.019,30.891C12.375,30.891 15.28,28.944 16.664,26.12C18.049,28.944 20.954,30.891 24.31,30.891C27.702,30.891 30.634,28.902 32,26.026C33.366,28.902 36.298,30.891 39.69,30.891C43.046,30.891 45.951,28.944 47.336,26.12C48.72,28.944 51.625,30.891 54.981,30.891C56.034,30.891 57.043,30.699 57.974,30.349L57.974,57.427C57.974,58.25 57.306,58.918 56.483,58.918L26.292,58.918L26.292,39.547C26.292,38.583 25.51,37.8 24.546,37.8L15.151,37.8C14.187,37.8 13.404,38.583 13.404,39.547L13.404,58.918L7.517,58.918C6.694,58.918 6.026,58.25 6.026,57.427ZM52.628,39.542C52.628,38.559 51.829,37.76 50.845,37.76L37.203,37.76C36.219,37.76 35.42,38.559 35.42,39.542L35.42,46.733C35.42,47.717 36.219,48.516 37.203,48.516L50.845,48.516C51.829,48.516 52.628,47.717 52.628,46.733L52.628,39.542Z"
                style={{ fill: fill }} // Füllfarbe dynamisch
            />
        </svg>
    );
}