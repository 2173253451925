import React, { useEffect } from 'react';
import './app.style.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import RequiredScreenSize from '../../theme/layout/required-screen/required-screen.component';
import ScrollToTop from '../../theme/hooks/scoll-to-top.component';
import CookieBanner from '../../theme/layout/cookie-banner/cookie-banner.component';
import DevRouter from '../dev/component/dev-router/dev-router.component';
import InfluspaceRouter from '../../influspace/influspace-router/influspace-router.component';
import ChatRouter from '../../influspace/chat-router/chat-router.component';
import Notifications from "../../theme/layout/notifications";
import Landing from "../../influspace/landing";
import '../../utility/localization.utility';
import Cookies from 'js-cookie';
import i18n from "i18next";
import { HasMessagesContextProvider } from "../../state/context/has-messages";
import {InAppPurchaseService} from "../../service/iap/InAppPurchaseService";
import {UTMTrackingService} from "../../service/analytics/tracking.service";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function App() {
    const query = useQuery();

    useEffect(() => {
        // Sprache initialisieren
        initializeLanguage();

        // In-App Purchases initialisieren
        InAppPurchaseService.initialize();
    }, []);

    useEffect(() => {
        // UTM-Tracking durchführen
        const currentUrl = window.location.href;
        UTMTrackingService.trackPageView(currentUrl, query);
    }, [query]);

    function initializeLanguage() {
        const cookieLanguageSet = Cookies.get('language_set');
        if (!cookieLanguageSet) {
            if (navigator.language === 'de-DE') {
                i18n.changeLanguage('de');
            }
        }
    }

    return (
        <>
            <ScrollToTop />
            <HasMessagesContextProvider>
                <Routes>
                    <Route path='/' element={<Landing />} />
                    <Route path='/dev/*' element={<DevRouter />} />
                    <Route path='/chat/*' element={<ChatRouter />} />
                    <Route path='*' element={<InfluspaceRouter />} />
                </Routes>
            </HasMessagesContextProvider>
            <CookieBanner />
            <RequiredScreenSize />
            <Notifications />
        </>
    );
}

export default App;
