import React from 'react'
import {Route, Routes} from 'react-router-dom'
import MissingError from '../../../app/component/error/missing/missing-error.component'
import {Config} from '../../../../service/config/config'
import BrandLabel from '../brand-label/brand-label'
import Checkbox from '../checkbox/dev-checkbox.component'
import Conversation from '../conversation/dev-conversation.component'
import Errors from '../errors/dev-errors'
import Grid from '../grid/dev-grid.component'
import ImageLoading from '../image-loading/dev-image-loading.component'
import InlineNotification from '../inline-notification/dev-inline-notification.component'
import InputButton from '../input-button/dev-input-button.component'
import InputDropdown from '../input-dropdown/dev-input-dropdown.component'
import InputTextArea from '../input-text-area/dev-input-text-area.component'
import InputText from '../input-text/dev-input-text.component'
import LoadingContent from '../loading-content/dev-loading-content.component'
import Loading from '../loading/dev-loading.component'
import Login from '../login/dev-login.component'
import MenuButton from '../menu/component/menu-button/menu-button.component'
import Menu from '../menu/index.component'
import Network from '../network/dev-network.component'
import Overlay from '../overlay/dev-overlay.component'
import Panel from '../panel/dev-panel.component'
import Placeholder from '../placeholder/dev-placeholder.component'
import ProfileTileGrid from '../profile-tile-grid/dev-profile-tile-grid.component'
import ProfileTile from '../profile-tile/dev-profile-tile.component'
import SlideShow from '../slide-show/slide-show.component'
import SystemInfo from '../system-info/dev-system-info.component'
import InputSlider from '../input-slider/dev-input-slider.component'
import DatePicker from '../date-picker/date-picker.component'
import DevWebSocket from '../web-socket/web-socket.component'
import DevNotifications from '../notifications/index.component';
import BoostButtonPlayground from "../boost-button-playground";

export default function DevRouter() {
    if (Config.isProduction)
        return <MissingError/>

    return <>
        <MenuButton/>
        <Routes>
            <Route path='/' element={<Menu/>}/>

            <Route path='/login' element={<Login/>}/>
            <Route path='/placeholder' element={<Placeholder/>}/>
            <Route path='/input-button' element={<InputButton/>}/>
            <Route path='/network' element={<Network/>}/>
            <Route path='/input-text' element={<InputText/>}/>
            <Route path='/input-text-area' element={<InputTextArea/>}/>
            <Route path='/panel' element={<Panel/>}/>
            <Route path='/errors' element={<Errors/>}/>
            <Route path='/profile-tile' element={<ProfileTile/>}/>
            <Route path='/profile-tile-grid' element={<ProfileTileGrid/>}/>
            <Route path='/loading' element={<Loading/>}/>
            <Route path='/image-loading' element={<ImageLoading/>}/>
            <Route path='/overlay' element={<Overlay/>}/>
            <Route path='/checkbox' element={<Checkbox/>}/>
            <Route path='/loading-content' element={<LoadingContent/>}/>
            <Route path='/input-dropdown' element={<InputDropdown/>}/>
            <Route path='/grid' element={<Grid/>}/>
            <Route path='/chat-box' element={<Conversation/>}/>
            <Route path='/inline-notifications' element={<InlineNotification/>}/>
            <Route path='/system-info' element={<SystemInfo/>}/>
            <Route path='/brand-label' element={<BrandLabel/>}/>
            <Route path='/slide-show' element={<SlideShow/>}/>
            <Route path='/input-slider' element={<InputSlider/>}/>
            <Route path='/date-picker' element={<DatePicker/>}/>
            <Route path='/boost-button' element={<BoostButtonPlayground/>}/>
            <Route path='/web-socket' element={<DevWebSocket/>}/>
            <Route path='/notifications' element={<DevNotifications/>}/>

            <Route path='*' element={<MissingError/>}/>
        </Routes>
    </>
}