import axios from "axios";
import { IPayPalPlan } from "./model/IPayPalPlan";
import {Subscription} from "./model/Subscription";

export class BillingService {
    private static root = "/billing";
    private static client = axios;

    static async cancelSubscription() {
        await this.client.post(`${this.root}/subscription/cancel`);
    }

    static async getSubscription(): Promise<Subscription> {
        console.log("getSubscription");
        const response = await this.client.get(`${this.root}/subscription`);
        return response.data;
    }

    static async subscribe(subscriptionId: string, provider: string): Promise<Subscription> {
        const response = await this.client.post(`${this.root}/subscription`, {
            subscriptionId,
            provider
        });
        return response.data;
    }

    static async getOpenPlanId(): Promise<string> {
        let response = await this.client.get(`${this.root}/open-plan-id`);
        return response.data.planId;
    }

    static async getPremiumPlans(): Promise<IPayPalPlan[]> {
        const response = await this.client.get(`${this.root}/plan/premium`);
        return response.data;
    }

    static async createOrder(name: string) {
        const response = await this.client.post(`${this.root}/order/create`, {name})
        return response.data.orderId
    }

    static async approveOrder(orderId: string) {
        await this.client.post(`${this.root}/order/approve`, {orderId})
    }
}
