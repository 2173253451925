import React, { useState } from 'react';
import style from './index.module.scss';
import { useNavigate } from 'react-router-dom';
import ISettings from "../../../../../../service/settings/model/settings.model";
import { useTranslation } from 'react-i18next';
import FollowerCount from "../../../../../../influspace/profile/component/profile-networks/component/follower-count/follower-count.component";
import NetworkItem from "../../../../../../influspace/profile/component/profile-networks/component/network-item/network-item.component";
import { INetwork } from "../../../../../../service/network/model/INetworkDetails";
import numberFormatService from "../../../../../../service/number-format.service";
import networkService from "../../../../../../service/network/network.service";
import INetworkDetails from "../../../../../../influspace/profile/component/profile-networks/models/network-details.model";

type ConnectDetailsProps = {
    settings: ISettings;
};

export default function NetworkDetails({ settings }: ConnectDetailsProps) {
    const { t } = useTranslation();
    const [networkDetails, setNetworkDetails] = useState<INetworkDetails>(settings.networks);
    const navigate = useNavigate();

    async function refreshNetworks() {
        const networkDetails = await networkService.getNetworks();
        setNetworkDetails(networkDetails);
    }

    const sortedNetworks = networkDetails.networks.sort((a, b) => b.follower - a.follower);
    const listProfiles = networkDetails.networks.map((network) => (
        <NetworkItem key={network.ref} network={network as INetwork} editingMode={true} onDisconnect={refreshNetworks} />
    ));
    const totalFollower = numberFormatService.renderNumber(networkDetails?.totalFollower);

    return (
        <>
            <div className={style.iconWithText}>
                <div className={style.icon} />
                <div>
                    <div>{t('network_details.networks')}</div>
                    <div className={style.info}>{t('network_details.manage_accounts')}</div>
                </div>
            </div>
            <div className={style.container}>
                <h4 className={'mb2'}>{t('network_details.connect_prompt')}</h4>
                <div className={style.connect_container}>
                    {listProfiles}
                    <FollowerCount totalFollower={totalFollower} editingMode={true} networks={networkDetails.networks} />
                </div>
            </div>
        </>
    );
}
