
export enum DetailView {
    Account = "Close",
    Billing = "Billing",
    Notification = "Notifications",
    Password = "Password",
    Mail = "Mail",
    Affiliate = "Affiliate",
    Networks = "Networks",
    Blocked = "Blocked"
}
