import React, { useState } from 'react'
import chatService from '../../../../service/chat/chat.service'
import ChatMessage from '../../../../service/chat/model/ChatMessage'
import InputTextArea from '../../../../theme/component/input-text-area/input-text-area.component'

import style from './index.module.scss'
import { OfferRef } from "../message-grid/component/message/component/offer-reference"
import { IOffer } from "../../../../service/marketplace/model/offer.model"
import { Receipient } from "../message-grid/component/message/model/receipient.model"
import ChatImageUpload from "../chat-image-upload/chat-image-upload.componet";
import {ImageType} from "../../../../service/image/models/image-type.model";
import imageService from "../../../../service/image/image.service";
import { useTranslation } from 'react-i18next';
import {Haptics, ImpactStyle} from "@capacitor/haptics";

type ChatboxProps = {
    offer: IOffer
    username: string
    onMessageSend: (message: ChatMessage) => void
}

interface ImagePreview {
    file: File;
    previewUrl: string;
}

export default function Chatbox({ offer, username, onMessageSend }: ChatboxProps) {
    const { t } = useTranslation();
    const [message, setMessage] = useState<string>('')
    const [sendDisabled, setSendDisabled] = useState<boolean>(false)
    const [selectedImages, setSelectedImages] = useState<ImagePreview[]>([])

    const handleImagesSelect = (files: File[]) => {
        const newPreviews = files.map(file => ({
            file,
            previewUrl: URL.createObjectURL(file)
        }));
        setSelectedImages(prev => [...prev, ...newPreviews]);
    }

    async function sendMessage() {
        if (sendDisabled || (!message.trim() && selectedImages.length === 0))
            return

        setSendDisabled(true)

        let fullMessage = message

        if (selectedImages.length > 0) {
            try {
                for (const image of selectedImages) {
                    const uploadDetails = await imageService.uploadImage(image.file, ImageType.Other)
                    fullMessage += `\n[img]${uploadDetails.ref}[/img]`
                }
            } catch (error) {
                console.error('Failed to upload image:', error)
                // Optionally, you can inform the user about the failed upload
            }
        }


        const messageResponse = await chatService.sendMessage(username, fullMessage, offer?.ref)
        await Haptics.impact({ style: ImpactStyle.Light });


        onMessageSend(messageResponse)

        setMessage('')
        setSelectedImages([])
        setSendDisabled(false)
    }

    const removeImage = (index: number) => {
        setSelectedImages(prevImages => prevImages.filter((_, i) => i !== index))
    }

    return (
        <div className={style.textbox}>
            <OfferRef receipient={Receipient.Sender} offer={offer ? {name: offer.name, description: offer.description, ref: offer.ref} : undefined}/>
            <div className={style.inputContainer}>
                {selectedImages.length > 0 && (
                    <div className={style.imagePreviewContainer}>
                        {selectedImages.map((image, index) => (
                            <div key={index} className={style.imagePreview}>
                                <img src={image.previewUrl} alt={`Preview ${index + 1}`}/>
                                <button
                                    className={style.removeImageButton}
                                    onClick={() => removeImage(index)}
                                    aria-label="Remove image"
                                />
                            </div>
                        ))}
                    </div>
                )}
                <InputTextArea
                    rows={4}
                    placeholder={t('chatbox.message_placeholder')}
                    onChange={setMessage}
                    onEnter={sendMessage}
                    maxLength={1000}
                    initialValue={message}
                    chat={true}
                />
                <ChatImageUpload onImagesSelect={handleImagesSelect}/>
            </div>
            <span
                className={`pre-icon ${style.sendButton} ${(sendDisabled || (!message.trim() && selectedImages.length === 0)) ? style.sendDisabled : ''}`}
                onClick={sendMessage}
            />
        </div>
    )
}