import { IOffer } from "../../../../../../../../service/marketplace/model/offer.model";
import style from './index.module.scss';
import React from "react";
import {
    IKeyword
} from "../../../../../../../profile/component/profile-audience/component/profile-keywords/model/profile-keyword.model";
import { Tag } from "../../../../../../../../theme/component/tag/tag.component";
import { useNavigate } from "react-router-dom";
import {PremiumBadge} from "../../../../../../../../theme/component/premium-badge";
import ProfileView from "../../../../../../../../service/profile/model/profile-view.model";
import KeywordTag from "../../../../../../../../theme/component/profile-tile/keyword-tag.component";
import {Config} from '../../../../../../../../service/config/config'
import SessionService from "../../../../../../../../service/session/session.service";
import InputButton from "../../../../../../../../theme/component/input-button";
import { useTranslation } from 'react-i18next';

const defaultAvatar = require('../../../../../../../../theme/resource/image/default/avatar_default.png')

type OfferHeaderProps = {
    offer: IOffer,
}
export function OfferHeader({ offer }: OfferHeaderProps) {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const loggedIn = SessionService.isAuthenticated();

    function Author({ profile }: { profile: ProfileView }) {
        return <span className={style.author} onClick={() => navigate(`/profile/${profile.username}`)}>
            {profile.displayname}
            <PremiumBadge premium={profile.premium} className={style.premium}/>
        </span>;
    }

    function Icon({ name, icon, show }: { name: string, icon: string, show: boolean }) {
        if (show) {
            return (
                <div className={style.tooltip}>
                    <div className={`pre-icon ${style.icon} ${style[icon]}`}></div>
                    <div className={style.info_item_name}>{name}</div>
                </div>
            );
        } else {
            return <div></div>;
        }
    }

    function Location({ name, icon, show }: { name: string, icon: string, show: boolean }) {
        if (show) {
            return (
                <div className='tooltip'>
                    <div id='bookmark' className={`pre-icon ${style.location} ${style[icon]}`}>
                        {name}</div>
                    <span className='tooltiptext'>{name}</span>
                </div>
            );
        } else {
            return <div className={`${style.location} ${style[icon]}`}></div>;
        }
    }

    function KeywordBar({ keywords }: { keywords: IKeyword[] }) {
        const sortedKeywords = keywords.sort((a, b) => {
            if (a.word && b.word) return a.word.length - b.word.length;
            return 0; // This handles cases where the word may be undefined
        });

        return <div className={style.keywords}>
            {sortedKeywords.slice(0, 6).map((keyword) =>
                <div key={keyword.ref}><KeywordTag keyword={keyword} key={keyword.ref} /></div>
            )}
        </div>;
    }

    function Name({ offer }: { offer: IOffer }) {
        return <div className={style.name}>{offer.name}
            {offer?.applied ? <Tag color={'green'} label={'Applied'} /> : <></>}
            {offer?.owned ? <Tag color={'blue'} label={'Dein Angebot'} /> : <></>}
        </div>;
    }

    function RegisterCTA() {
        return <InputButton className={'mt2'} label={t('offer_header.cta.sign_up')} onClick={() => navigate('/join')} />;
    }

    function onAvatarClick() {
        navigate(`/profile/${offer.author.username}`);
    }

    const avatarUrl = offer.image ? `/api/image?ref=${offer.image}` : offer.avatarUrl;
    const avatar = avatarUrl ? Config.host + avatarUrl : defaultAvatar;

    return <div className={style.header}>
        <div className={style.avatar_container} onClick={onAvatarClick}>
            <img className={style.avatar} src={avatar} alt='Profile Avatar'/>
        </div>
        <div className={style.info_bar}>
            <Name offer={offer}/>
            <div className={style.info_item}>
                <Author profile={offer.author}/>
            </div>
            <div className={style.info_item_labels}>
                <Icon icon={'product_placement'} name={'Placement'} show={offer?.collaborations?.productPlacement}/>
                <Icon icon={'free_placement'} name={'Free Product'} show={offer?.collaborations?.freeProductPlacement}/>
                <Icon icon={'influencer'} name={'Collaborations'} show={offer?.collaborations?.influencerCollaboration}/>
            </div>
            <div className={style.info_item}>
                <KeywordBar keywords={offer.keywords}/>
            </div>
            <div className={style.description_short}>{offer.description}</div>
            {!loggedIn ? <RegisterCTA/> : <></>}
        </div>
    </div>;
}
