import {ProductMapper} from "./ mapper/ProductMapper";
import {ProductEnum} from "./model/ProductEnum";
import {IProduct} from "./model/IProduct";
import {LOG_LEVEL, Purchases} from "@revenuecat/purchases-capacitor";
import {Capacitor} from "@capacitor/core";
import {BillingService} from "../billing/billing.service";
import sessionService from "../session/session.service";


export class InAppPurchaseService {
    private static initialized = false;

    /**
     * Initialisiert den Store und registriert Produkte.
     */
    static initialize() {
        if (this.initialized) {
            console.warn('InAppPurchaseService is already initialized.');
            return;
        }


        document.addEventListener('deviceready', async () => {
            await Purchases.setLogLevel({level: LOG_LEVEL.DEBUG});
            if (Capacitor.getPlatform() === 'ios' && sessionService.isAuthenticated()) {
                await Purchases.configure({ apiKey: 'appl_dwgWnTWcbhDHfdpINNZuBBFyUNQ' });
                await this.purchase(ProductEnum.PREMIUM_MONTHLY)
            } else if (Capacitor.getPlatform() === 'android') {
                //await Purchases.configure({ apiKey: <public_google_api_key> });
            }
        });
    }

    public static async purchase(product: ProductEnum) {
        const pId = ProductMapper.getProduct(product).id
        const storeProduct = (await Purchases.getProducts({productIdentifiers: [pId]})).products[0]
        const result = await Purchases.purchaseStoreProduct({product: storeProduct})
        await BillingService.subscribe(result.customerInfo.originalAppUserId, 'revenueCat' )
        console.log("PurchaseResult:", result)
    }

    /**
     * Lädt ein Produkt basierend auf dem ProductEnum.
     * @param productEnum Enum des Produkts
     * @returns Produktdetails
     */
    static getProduct(productEnum: ProductEnum): IProduct | null {
        return ProductMapper.getProduct(productEnum);
    }
}
