import InputButton from "../../../../../../../../theme/component/input-button";
import {ButtonColor} from "../../../../../../../../theme/component/input-button/models/button-color.model";
import style from "./DashboardHeader.module.scss";
import React, {useEffect, useState} from "react";
import {Config} from "../../../../../../../../service/config/config";
import SessionService from "../../../../../../../../service/session/session.service";
import {NotificationService} from "../../../../../../../../service/notification/notification.service";
import {NotificationType} from "../../../../../../../../service/notification/model/notification-type.enum";
import {WithdrawPopup} from "./component/withdraw-popup/WithdrawPopup";
import {AffiliateSettingsModel} from "../../../../../../../../service/settings/model/affiliate-settings.model";

type DashboardHeaderProps = {
    affiliateSettings: AffiliateSettingsModel;
}

export function DashboardHeader({ affiliateSettings }: DashboardHeaderProps) {
    const [referralLink, setReferralLink] = useState<string>()
    const [showWithdrawPanel, setShowWithdrawPanel] = useState(false)
    const [canWithdraw, setCanWithdraw] = useState(false)
    const [withdrawButtonColor, setWithdrawButtonColor] = useState<ButtonColor>(ButtonColor.Warning)

    useEffect(() => {
        setReferralLink(Config.host + '/join?ref=' + SessionService.getUsername());

        if (affiliateSettings.availableCredits > 1000) {
            setWithdrawButtonColor(ButtonColor.Success);
        } else {
            setWithdrawButtonColor(ButtonColor.BLACK);
        }

    }, []);

    function copyReferralLink() {
        navigator.clipboard.writeText(referralLink);

        NotificationService.displayNotification({
            message: 'Referral link copied to clipboard',
            type: NotificationType.success
        });
    }

    function handleWithdraw() {

        if (canWithdraw === false) {
            NotificationService.displayNotification({
                message: 'You have to have at least 10€ credits to withdraw.',
                type: NotificationType.failure
            });

            return
        }

        setShowWithdrawPanel(true)
    }

    return (
        <div className={style.container}>
            <InputButton label={'Copy Referral Link'}
                         color={ButtonColor.Secondary}
                         onClick={copyReferralLink} />
            {/*<span className={style.referralLink}>{referralLink}</span>*/}
            <InputButton className={style.withdrawButton}
                         label={'Withdraw Earnings'}
                         color={canWithdraw ? ButtonColor.Success : ButtonColor.BLACK}
                         onClick={handleWithdraw} />
            <WithdrawPopup showWithdraw={showWithdrawPanel}
                           setShowWithdraw={setShowWithdrawPanel} />
        </div>
    )
}