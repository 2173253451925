import React, {useEffect, useState} from "react";
import style from "./DashboardMeta.module.scss";
import {AffiliateSettingsModel} from "../../../../../../../../service/settings/model/affiliate-settings.model";
import {formatCurrency} from "../../../../../../../../utility/currencyFormat.utility";
import {WithdrawPopup} from "../dashboard-header/component/withdraw-popup/WithdrawPopup";

type DashboardMetaProps = {
    affiliateSettings: AffiliateSettingsModel;
}

export function DashboardMeta({ affiliateSettings }: DashboardMetaProps) {
    const [totalRewards, setTotalRewards] = useState<string>()
    const [availableCredits, setAvailableCredits] = useState<string>()
    const [totalReferrals, setTotalReferrals] = useState<number>()

    useEffect(() => {
        console.log(affiliateSettings)

        const totalRewards = formatCurrency(affiliateSettings.totalRewards, '€');
        setTotalRewards(totalRewards)

        const availableCredits = formatCurrency(affiliateSettings.availableCredits, '€');
        setAvailableCredits(availableCredits)

        const totalReferrals = affiliateSettings.referrals.length;
        setTotalReferrals(totalReferrals)
    }, []);

    return (
        <div className={style.stats_section}>
            <div className={style.stat_box}>
                <h2>Total Returned</h2>
                <span id={style.total_returned}>{totalRewards}</span>
            </div>
            <div className={style.stat_box}>
                <h2>Available</h2>
                <span id={style.available_euro}>{availableCredits}</span>
            </div>
            <div className={style.stat_box}>
                <h2>Users Referred</h2>
                <span id={style.total_referred}>{totalReferrals}</span>
            </div>
        </div>
    )
}